import React, { useContext, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { Link, useNavigate } from "react-router-dom";
import "../../assets/css/style.scss";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { AppContext } from "../../context/user/AppContext";
import Loader from "../../sharedComponent/Loader";
import ic_prev_image from "../../assets/images/AddVehicles/package_image_01.png";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import swal from "sweetalert";

const MatchVechicleWithCargo = () => {
  const [loading, setLoading] = useState(false);
  var commaNumber = require("comma-number");

  const navigate = useNavigate();
  const { mainClassB, customerData, ApiCaaling, setApiCalling } =
    useContext(AppContext);
  const previousData = JSON.parse(localStorage.getItem("request"));
  const [loadId, setLoadID] = useState(
    previousData ? previousData.trip_id : ""
  );
  const [unassignedCargo, setUnassignedCargo] = useState("");
  const [vehicle_cargo_mapping, setVehicle_cargo_mapping] = useState([]);
  const [transporterVehicleList, setTransporterVehicleList] = useState([]);
  const [qtyErrro, setQtyError] = useState(false);
  const [unitType, setUnitType] = useState("");
  const [itemQuantity, setItemQuantity] = useState("");
  const [sumOfQuantity, setSumOfQuantity] = useState(0);
  // const sumOfQuantity = vehicle_cargo_mapping.reduce(
  //   (total, item) => total + item.quantity,
  //   0
  // );

  const quintalValue = Number(unassignedCargo) * 10;
  // const filteredData = vehicle_cargo_mapping.filter(
  //   (item) => "enteredValue" in item
  // );

  const filteredData = vehicle_cargo_mapping.filter(item => typeof item.enteredValue === 'number' && !isNaN(item.enteredValue));

  console.log("vehicle_cargo_mapping", vehicle_cargo_mapping);
  console.log("filteredData", filteredData);
  const submit = async () => {
    if (!vehicle_cargo_mapping.length) {
      swal("please assign the cargo atlease once");
    }
    // else if (qtyErrro) {
    //   swal("Entered Quantity is greater then Capacity");
    // }
    // if (sumOfQuantity > quintalValue || sumOfQuantity > unassignedCargo) {
    //   swal("Entered Quantity is greater then Quantity of Cargo to pair");
    // }
    else {
      
      setApiCalling(true);
      await postWithAuthCallWithErrorResponse(
        ApiConfig.VEHICLE_CARGO_MATCHMAKING,
        JSON.stringify({
          ...customerData,
          load_id: loadId,
          load_capacity: unassignedCargo,
          vehicle_cargo_mapping: filteredData,
        })
      ).then((res) => {
        setApiCalling(false);
        if (res.json.result) {
          swal(res.json.message);
          navigate("/DirectOrderHeader");
        } else if (res.json.remaining_quantity) {
          swal({
            title: `${
              "Remaining Quantity" +
              commaNumber(Math.round(res.json.remaining_quantity).toFixed(2))
            }`,

            text: res.json.message,
            icon: "warning",
            dangerMode: true,
          }).then(navigate("/VehicleRequest"));
        } else {
          swal({
            // title: `${
            //   "Remaining Quantity" +
            //   commaNumber(Math.round(res.json.remaining_quantity).toFixed(2))
            // }`,

            text: res.json.message,
            icon: "warning",
            dangerMode: true,
          });
        }
      });
    }
  };

  const getTransporterVehicleList = () => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.MATCHMAKING_TRANSPORTER_VEHICLE_LIST,
      JSON.stringify({ ...customerData, load_id: loadId })
    ).then((res) => {
      if (res.json.result) {
        setTransporterVehicleList(res.json.transporter_list);
        setUnassignedCargo(res.json.load_capacity);
        let vehicleData = [];
        res.json.transporter_list.map((vehicle) => {
          vehicle.vehicle_list.map((item) => {
            let vehiclelist = {
              trip_vehicle_id: item?.trip_vehicle_id,
              quantity: item?.vehicle_capacity,
              transporter_id: vehicle?.transporter_id,
            };

            vehicle_cargo_mapping.map((cargo) => {
              if (vehicle.vehicle_capacity);
            });

            vehicleData.push(vehiclelist);
          });
        });

        setVehicle_cargo_mapping(vehicleData);

        setUnitType(res.json.unit);
      }
    });
  };

  useEffect(() => {
    getTransporterVehicleList();
    return () => {};
  }, []);

  return (
    <React.Fragment>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <main className={`direct-order-main ${mainClassB}`} id="cxMainB">
          <div style={{ margin: "20px" }}>
            <div className=" all-request">
              <div className="  ">
                <div className="offerLoadsMainLists">
                  <div className="mainLoadTable">
                    <table
                      className="dataList custom-table"
                      style={{ minWidth: "1170px" }}
                    >
                      <thead>
                        <tr>
                          <th>
                            <label>S.No</label>
                          </th>
                          <th>
                            <label>Transporter</label>
                          </th>
                          <th>
                            <label>Selected Vehicle</label>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {transporterVehicleList?.map((list, index) => {
                          return (
                            <tr key={"list" + index}>
                              <td className="custom-width1">{index + 1}</td>
                              <td className="custom-width">
                                <div className="transporter-details">
                                  <div className="row g-0 mb-3 align-items-center">
                                    <div className="col-sm-3">
                                      <div className="tr-img-box">
                                        <img
                                          src={
                                            list.user_profile_pic &&
                                            list.user_profile_pic.length > 0
                                              ? ApiConfig.BASE_URL_FOR_IMAGES +
                                                list.user_profile_pic
                                              : ic_prev_image
                                          }
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-sm-9 ">
                                      <div className="tr-text-box">
                                        <div className="tr-name">
                                          <p className="px-2">
                                            {list.user_name}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>

                              <td className="custom-width">
                                {list?.vehicle_list.map(
                                  (vehicle, innerIndex) => {
                                    const mappingIndex =
                                      index * list.vehicle_list.length +
                                      innerIndex;

                                    return (
                                      <div
                                        className="transporter-details"
                                        key={"vehicle" + innerIndex}
                                      >
                                        <div className="row g-0 mb-3">
                                          <div className="col-sm-3">
                                            <div className="tr-img-box">
                                              <img
                                                src={
                                                  vehicle.vehicle_image &&
                                                  vehicle.vehicle_image.length >
                                                    0
                                                    ? ApiConfig.BASE_URL_FOR_IMAGES +
                                                      vehicle.vehicle_image
                                                    : ic_prev_image
                                                }
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                          <div className="col d-flex justify-content-between align-items-center ">
                                            <div className="tr-text-box">
                                              <div className="tr-tarrif">
                                                <p>
                                                  Vehicle Type{" "}
                                                  <span>
                                                    {vehicle.vehicle_type}
                                                  </span>
                                                </p>
                                              </div>
                                              <div className="tr-Destination">
                                                <p>
                                                  Plate No.{" "}
                                                  <span>
                                                    {vehicle.plate_number}
                                                  </span>
                                                </p>
                                              </div>
                                              <div className="tr-Destination">
                                                <p>
                                                  Capacity{" "}
                                                  <span>
                                                    {vehicle.vehicle_capacity}{" "}
                                                    Quintals
                                                  </span>
                                                </p>
                                              </div>
                                            </div>
                                            <div className="d-flex align-items-center">
                                              <div class="input-group mb-3">
                                                <input
                                                  type="text"
                                                  class="form-control rounded-0"
                                                  onChange={(e) => {
                                                    const newMapping = [
                                                      ...vehicle_cargo_mapping,
                                                    ];
                                                    newMapping[mappingIndex] = {
                                                      ...newMapping[
                                                        mappingIndex
                                                      ],
                                                      enteredValue: parseInt(
                                                        e.target.value
                                                      ), // Update enteredValue property
                                                    };
                                                    setVehicle_cargo_mapping(
                                                      newMapping
                                                    );
                                                    newMapping.map((item) => {
                                                      if (
                                                        item.quantity >
                                                        vehicle.vehicle_capacity
                                                      ) {
                                                        setQtyError(true);
                                                      } else {
                                                        setQtyError(false);
                                                      }
                                                      setItemQuantity(
                                                        item.quantity
                                                      );
                                                    });
                                                    // Calculate sumOfQuantity here
                                                    const newSumOfEnteredValues =
                                                      newMapping.reduce(
                                                        (total, item) =>
                                                          total +
                                                          (item.enteredValue ||
                                                            0),
                                                        0
                                                      );
                                                    setSumOfQuantity(
                                                      newSumOfEnteredValues
                                                    );
                                                  }}
                                                />
                                                <span class="input-group-text">
                                                  Quintals
                                                </span>
                                              </div>

                                              <div className="assign-cargo">
                                                <p>
                                                  {
                                                    vehicle.cargo_details
                                                      ?.cargo_type_ref_no
                                                  }
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <label>
                      Quantity of Cargo to pair:{" "}
                      <span className="text-danger">
                        {unitType === "ton"
                          ? `${commaNumber(
                              Math.round(quintalValue).toFixed(2)
                            )}`
                          : unassignedCargo}{" "}
                        (Quintals)
                        {``}
                        {/* {unassignedCargo} {unitType} */}
                      </span>
                    </label>
                  </div>
                  <div className="bottom-btn-wrapper">
                    {/* <Link to="/DirectOrderConfirmation"> */}
                    <button type="button" onClick={submit}>
                      Submit
                    </button>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      )}
    </React.Fragment>
  );
};

export default MatchVechicleWithCargo;
