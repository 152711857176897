import React, { useContext, useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import { Link, useParams } from "react-router-dom";
import location from "../../assets/images/location (2) (1).svg";
import ic_arrow from "../../assets/images/ic_arrow.svg";
import seeker_default from "../../assets/images/seeker_default.svg";
import freightsimg from "../../assets/images/freightsimg.png";
import ic_call_big from "../../assets/images/ic_call_big.svg";
import ic_dropdown_arrow from "../../assets/images/dropdown_arrow_blue.svg";
import tick_circle from "../../assets/images/header/tick_circle.svg";
import document from "../../assets/images/header/document.png";
import ic_options from "../../assets/images/Trasnvehicle/ic_options.svg";
import search from "../../assets/images/Trasnvehicle/search.svg";
import { AppContext } from "../../context/user/AppContext";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";

const GPS_Shipper = () => {
  let params = useParams();
  let id = params.id;
  const [frights, setFrights] = useState([]);
  const [SideBtn, setSideBtn] = useState(false);
  const showsidemenu = () => setSideBtn(!SideBtn);
  const [currentFright, setCurrentFright] = useState({});
  const [frightDetails, setFrightDetails] = useState({
    load_details: { trip_reference_no: "" },
    vehicles: [],
  });
  const { mainClassB, customerData } = useContext(AppContext);
  const [map, setMap] = useState({
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 5,
  });
  useEffect(() => {
    if (id) setCurrentFright({ trip_id: id });
    getFrights();
  }, []);
  useEffect(() => {
    getFrightDetails();
  }, [currentFright]);
  const getFrights = () => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.ONGOING_FRIGHTS,
      JSON.stringify(customerData)
    )
      .then((res) => {
        if (res.json.result) {
          setFrights(res.json.load_list);
          // setCurrentFright(res.json.load_list[0])
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getFrightDetails = () => {
    let url = id
      ? ApiConfig.LIVE_TRACKING
      : ApiConfig.LIVE_TRACKING_VEHICLE_LIST;
    postWithAuthCallWithErrorResponse(
      ApiConfig.LIVE_TRACKING,
      JSON.stringify({ ...customerData, load_id: currentFright.trip_id })
    )
      .then((res) => {
        if (res.json.result) {
          setFrightDetails(res.json);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    let lat =
      frightDetails.vehicles &&
      frightDetails.vehicles.length &&
      frightDetails.vehicles[0].current_location &&
      frightDetails.vehicles[0].current_location.latitude;
    let lng =
      frightDetails.vehicles &&
      frightDetails.vehicles.length &&
      frightDetails.vehicles[0].current_location &&
      frightDetails.vehicles[0].current_location.longitude;
    if (lat != "0" && lng != "0") setMap({ ...map, center: { lat, lng } });
  }, [frightDetails]);

  useEffect(() => {}, [map]);
  return (
    <div>
      <main className={`cx-main main-live-map p-0 ${mainClassB}`} id="cxMainB">
        <section id="cxs-live">
          <div id="cxl-map" style={{ height: "100vh" }}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyC_LIyr2j70_CHfDku12L5YW8gSCb1p8aQ",
              }}
              defaultCenter={map.center}
              defaultZoom={map.zoom}
            >
              {frightDetails.vehicles &&
                frightDetails.vehicles.length &&
                frightDetails.vehicles.map((vehicle, index) => {
                  return (
                    <div
                      lat={vehicle.current_location.latitude}
                      lng={vehicle.current_location.longitude}
                    >
                      <img src={location} alt="" />
                    </div>
                  );
                })}
            </GoogleMapReact>
            {/* <<===============>> Ongoing Freights Side Menu Start <<===============>> */}

            <div
              className={
                SideBtn ? "main-side-freight-menu" : " active-side-menu"
              }
              id=""
            >
              <button
                type="button"
                className=" toggle_button"
                onClick={showsidemenu}
              >
                <img
                  className="toggle_arrow"
                  style={{ width: "20px", height: "20px" }}
                  src={ic_arrow}
                  alt=""
                />
              </button>
              <div className="ongoing-freights-side">
                <div className="inside">
                  <h4 className="card-heading mb-4">Ongoing Freights </h4>
                  {frights.map((fright, index) => {
                    return (
                      <div
                        className="d-flex justify-content-between first_Freight  border-top border-bottom "
                        key={"fright" + index}
                      >
                        <img
                          className="seekerimg "
                          src={seeker_default}
                          alt=""
                        />

                        <div className="m-3">
                          <h6 className="card-title">
                            {fright.trip_company_name}
                          </h6>
                          <label>
                            {fright.trip_start_city} {fright.trip_start_country}{" "}
                            - {fright.trip_end_city} {fright.trip_end_country}
                          </label>{" "}
                          <br />
                          <Link
                            className="text-decoration-none View-Details-link"
                            to="#offcanvasDriverAccount"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasFrightDetailsMenu"
                            aria-controls="offcanvasRightAccount"
                            onClick={() => {
                              setCurrentFright(fright);
                              setSideBtn(false);
                            }}
                          >
                            View Details
                          </Link>
                        </div>
                        <a href="">
                          <img className="p-2" src={ic_call_big} alt="" />
                        </a>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            {/* <<===============>> Ongoing Freights Side Menu End <<===============>> */}

            {/* <<===============>> Freights Details Start <<===============>> */}

            <div className=" FreightsDetails-live-menu">
              <div
                className="Account main-body-live offcanvas offcanvas-end"
                tabindex="-1"
                id="offcanvasFrightDetailsMenu"
                aria-labelledby="offcanvasRightLabel"
              >
                <div className="offcanvas-header justify-content-between align-item-center">
                  <div className="ontitle   ">
                    <h5 className="ontitle">Freight Details </h5>
                  </div>

                  <button
                    type="button"
                    className="btn-close text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>

                <div className="inside ">
                  <div className="d-flex justify-content-between first_Freight mb-4">
                    <div className="d-flex">
                      <img
                        className="topimg-live"
                        src={freightsimg}
                        alt="img-freight"
                      />

                      <div className="ms-3">
                        <p className="card-title">
                          {frightDetails.load_details.trip_reference_no}
                        </p>
                        <label className="label">
                          {frightDetails.load_details.trip_start_city +
                            " " +
                            frightDetails.load_details.trip_start_country +
                            " - " +
                            frightDetails.load_details.trip_end_city +
                            " " +
                            frightDetails.load_details.trip_end_country}
                        </label>
                      </div>
                    </div>

                    <div className="option-dropdown dropdown ">
                      <a
                        href=""
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img src={ic_options} alt="" />
                      </a>
                      <ul className="dropdown-menu ">
                        <li>
                          <Link
                            to={
                              "/ViewDetails/" +
                              frightDetails.load_details.trip_id
                            }
                            className="text-decoration-none"
                          >
                            <button className="dropdown-item" type="button">
                              View Details
                              <img
                                src={ic_dropdown_arrow}
                                className="arrowvehicle"
                                alt=""
                              />
                            </button>
                          </Link>
                        </li>
                        <li>
                          <button
                            className="dropdown-item"
                            type="button"
                            to="#offcanvasDriverAccount"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasFrightStatus"
                            aria-controls="offcanvasRightAccount"
                          >
                            View Status
                          </button>
                        </li>
                        <li>
                          <button className="dropdown-item" type="button">
                            Call
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="justify-content-end">
                    <div className="d-flex">
                      <label className="pe-3">Cargo Type</label>
                      <p className="dt">
                        {frightDetails.load_details.cargo_type}
                      </p>
                    </div>
                    <div className="d-flex justify-content-start ">
                      <label className="pe-3">Quantity</label>
                      <p className="dt">
                        {frightDetails.load_details.quantity}
                      </p>
                    </div>
                    <div className="d-flex justify-content-starat ">
                      <label className="pe-3">Start Date</label>
                      <p className="dt">
                        {frightDetails.load_details.trip_start_date}{" "}
                      </p>
                    </div>
                    <div className="d-flex justify-content-star ">
                      <label className="pe-3">Est. End Date</label>
                      <p className="dt">
                        {frightDetails.load_details.trip_end_date}
                      </p>
                    </div>
                  </div>
                </div>
                <div className=" middle_bar">
                  <h4 className="card-gods ">Vehicles</h4>
                </div>
                <div className="inside " style={{ marginBottom: "80px" }}>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    style={{ height: "36px" }}
                  >
                    <option selected>Select Transporters</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                  <div className="form-outline searchBox mt-3 mb-3 d-flex">
                    <input
                      id="search-focus"
                      type="text"
                      placeholder="Search"
                      className="form-control searchText"
                    />
                    <a href="">
                      <img src={search} className="ic-search" alt="" />
                    </a>
                  </div>
                  {frightDetails.vehicles.map((vehicle, index) => {
                    return (
                      <div
                        className="d-flex justify-content-between first_Freight border-top border-bottom mb-2"
                        key={"vehicle" + index}
                      >
                        <div className="d-flex mb-3 mt-3">
                          <img
                            className="me-3 topimg-live"
                            src={freightsimg}
                            alt=""
                          />
                          <div>
                            <h6 className="card-title">
                              {vehicle.plate_number}
                            </h6>
                            <label>1500km away</label>
                            <label>. 2 days</label>
                            <div className="d-flex">
                              <label className="pe-2 ">Current Location</label>
                              <p className="dt">Viman Nagar</p>
                            </div>
                          </div>
                        </div>
                        <div className="option-dropdown dropdown mt-3">
                          <a
                            href=""
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img src={ic_options} alt="" />
                          </a>
                          <ul className="dropdown-menu ">
                            <li>
                              <Link to="#" className="text-decoration-none">
                                <button className="dropdown-item" type="button">
                                  View Details
                                  <img
                                    src={ic_dropdown_arrow}
                                    className="arrowvehicle"
                                    alt=""
                                  />
                                </button>
                              </Link>
                            </li>
                            <li>
                              <button
                                className="dropdown-item"
                                type="button"
                                to="#offcanvasDriverAccount"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasFrightStatus"
                                aria-controls="offcanvasRightAccount"
                              >
                                View Status
                              </button>
                            </li>
                            <li>
                              <button className="dropdown-item" type="button">
                                Call
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            {/* <<===============>> Freights Details End <<===============>> */}

            {/* <<===============>> Freights Status Start <<===============>> */}

            <div className=" FreightsStatus-live-menu">
              <div
                className="Account offcanvas offcanvas-end main-body-live"
                tabIndex="-1"
                id="offcanvasFrightStatus"
                aria-labelledby="offcanvasRightLabel"
              >
                <div className="offcanvas-header justify-content-between border-bottom">
                  <div className="ontitle text-center ">
                    <h5 className="statusheadding">Status</h5>
                  </div>

                  <button
                    type="button"
                    className="btn-close text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <ul
                  className="aby-load-status list-unstyled ps-3 mt-4"
                  style={{ marginBottom: "80px" }}
                >
                  <li className="aby-completed cx-status-cleft">
                    <div className="aby-status-circle">
                      <img src={tick_circle} />
                    </div>
                    <div className="status-details">
                      <div className="ms-2">
                        <h6 className="statusmenu">
                          Transporter has initiated the trip
                        </h6>
                        <label>At 12:30 PM</label>
                      </div>
                    </div>
                  </li>
                  <li className="aby-completed cx-status-cleft">
                    <div className="aby-status-circle">
                      <img src={tick_circle} />
                    </div>
                    <div className="status-details">
                      <div className="ms-2">
                        <h6 className="statusmenu">Reached Pickup Location</h6>
                        <label>At 12:45 PM</label>
                      </div>
                    </div>
                  </li>
                  <li className="aby-completed cx-status-cleft">
                    <div className="aby-status-circle">
                      <img src={tick_circle} />
                    </div>

                    <div className="status-details-image">
                      <div className="ms-2">
                        <h6 className="statusmenu">
                          Verification Documents - Loading
                        </h6>
                        <label className="timePM">Uploaded at 1:00 PM</label>
                        <div className="row ">
                          <div className=" d-flex  col-lg-4  mt-3">
                            <div className="statusIMg">
                              <img
                                className="me-4 docu"
                                src={document}
                                alt=""
                              />
                            </div>

                            <div className="statusIMg">
                              <img
                                className="me-4 docu"
                                src={document}
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="aby-completed cx-status-cleft">
                    <div className="aby-status-circle">
                      <img src={tick_circle} />
                    </div>
                    <div className="status-details ">
                      <div className="ms-2">
                        <h6>Loading Started</h6>
                        <label>At 3:30 PM</label>
                      </div>
                    </div>
                  </li>
                  <li className="aby-completed cx-status-cleft">
                    <div className="aby-status-circle">
                      <img src={tick_circle} />
                    </div>
                    <div className="status-details">
                      <div className="ms-2">
                        <h6>Loading Completed</h6>
                        <label>At 4:30 PM</label>
                      </div>
                    </div>
                  </li>
                  <li className="aby-completed cx-status-cleft">
                    <div className="aby-status-circle">
                      <img src={tick_circle} />
                    </div>
                    <div className="status-details">
                      <div className="ms-2">
                        <h6>Reached Destination</h6>
                        <label>At 5:30 PM</label>
                      </div>
                    </div>
                  </li>
                  <li className="aby-completed cx-status-cleft">
                    <div className="aby-status-circle">
                      <img src={tick_circle} />
                    </div>
                    <div className="status-details">
                      <div className="ms-2">
                        <h6>Verification Documents - Unloading</h6>

                        <img className="me-4 docu" src={document} alt="" />
                        <img className="docu" src={document} alt="" />
                        <label className="timePM">At 5:30 PM</label>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            {/* <<===============>> Freights Status End <<===============>> */}
          </div>

          {/* <div
            className="ongoingFreights-content offcanvas offcanvas-end"
            tabIndex="-1"
            id="ongoingFreights"
            aria-labelledby="offcanvasRightLabelongoingFreights"
            style={{ top: "60px", zIndex: "1000" }}
          >
            <OngoingFreightsMenu />
          </div> */}
        </section>
      </main>
    </div>
  );
};

export default GPS_Shipper;
