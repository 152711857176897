import React, { useContext } from "react";
import ic_options_menu from "../assets/images/Trasnvehicle/ic_options.svg";
import { AppContext } from "../context/user/AppContext";
import { Link } from "react-router-dom";
import ic_options from "../assets/images/Trasnvehicle/ic_options.svg";
import dropdown_arrow_blue from "../assets/images/dropdown_arrow_blue.svg";
import { useEffect } from "react";
import { postWithAuthCallWithErrorResponse } from "../api/ApiServices";
import { useState } from "react";
import ApiConfig from "../api/ApiConfig";
const Notifications = () => {
  const { mainClass, customerData } = useContext(AppContext);
  const [notification, setNotification] = useState([]);

  useEffect(() => {
    getNotification();
  }, []);

  const getNotification = () => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.NOTIFICATION,
      JSON.stringify({ ...customerData })
    ).then((res) => {
      if (res.json.result) setNotification(res.json.notifications);
    });
  };
  return (
    <>
      <main className={`main-body-Notification ${mainClass}`} id="cxMain">
        {notification.map((notify, index) => {
          return (
            <section className="common-body notification-block mb-2">
              <div className="top d-flex justify-content-between ">
                <p className="notifications mb-0">{notify.notification_text}</p>
                <div className="option-dropdown dropdown ">
                  <Link
                    to="#"
                    href=""
                    className=""
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img src={ic_options} alt="" className="option-menu" />
                  </Link>
                  <ul className="dropdown-menu ">
                    <li>
                      <button className="dropdown-item" type="button">
                        Delete
                        <img
                          src={dropdown_arrow_blue}
                          className="orderConArow"
                          alt=""
                        />
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <label htmlFor="">{notify.notification_time}</label>
            </section>
          );
        })}
      </main>
    </>
  );
};

export default Notifications;
