import React, { useContext, useState } from "react";
import package_image_01 from "../../assets/images/AddVehicles/package_image_01.png";
import package_image_02 from "../../assets/images/AddVehicles/package_image_02.png";
import circle_cross from "../../assets/images/circle_cross.svg";
import ic_vehicle from "../../assets/images/AddVehicles/ic_vehicle.svg";

import { Modal, Button } from "react-bootstrap";

import { Link } from "react-router-dom";
import dropdown_arrow_blue from "../../assets/images/dropdown_arrow_blue.svg";
import ic_options from "../../assets/images/Trasnvehicle/ic_options.svg";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { AppContext } from "../../context/user/AppContext";
const SelectVehicleSpecificationsList = () => {
  $(document).ready(function () {
    $(".dataList").DataTable();
  });
  const [fullscreenpopup, setFullscreenpopup] = useState(false);
  const { mainClass } = useContext(AppContext);
  const [fullscreen, setFullscreen] = useState(true);

  function handleShowscreen(breakpoint) {
    setFullscreen(breakpoint);
    setFullscreenpopup(true);
  }
  const handlefullscreen = () => setFullscreenpopup(true);
  const handlefullscreenClose = () => setFullscreenpopup(false);
  return (

    <main>

      <div className={`vehicle ${mainClass}`} id="cxMain">
        <div className="row">
          <div style={{ width: "100%", overflow: "auto", padding: "30px 0" }}>
            <table className="table-striped dataList" style={{ maxWidth: "1400px" }}>
              <thead>
                <tr>
                  <th></th>
                  <th>S.No</th>
                  <th>Company Type</th>
                  <th>Company Name</th>
                  <th>Contact Person</th>
                  <th>Fleet Size</th>
                  <th>Vehicles Available</th>
                  <th>City</th>
                  <th>Country</th>
                  <th>Contract</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr className="Rowbgcolor ">
                  <div className="form-check pt-2 ms-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckChecked"
                    />
                  </div>
                  <td>1</td>
                  <td>Transport</td>
                  <td>Abay Logistics</td>
                  <td>Bastian (9345356732)</td>
                  <td>35</td>
                  <td>20</td>
                  <td>Ethiopia</td>
                  <td>Africa</td>
                  <td>Permanent</td>
                  <td>Active</td>
                  <div className="option-dropdown dropdown p-2">
                    <a
                      href=""
                      className="ms-5 ps-5"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img className="" src={ic_options} alt="" />
                    </a>
                    <ul className="dropdown-menu ">
                      <li>
                        <button className="dropdown-item" type="button">
                          Edit{" "}
                          <img
                            src={dropdown_arrow_blue}
                            className="arrowvehicle"
                            alt=""
                          />
                        </button>
                      </li>
                      <li>
                        <Link
                          to="/ViewDetails"
                          className="text-decoration-none"
                        >
                          <button className="dropdown-item " type="button">
                            View Details
                          </button>
                        </Link>
                      </li>
                      <li></li>

                      <li>
                        <button className="dropdown-item" type="button">
                          Change Over
                        </button>
                      </li>
                      <li>
                        <button className="dropdown-item" type="button">
                          Block
                        </button>
                      </li>
                    </ul>
                  </div>
                </tr>
                <tr className="Rowbgcolor p-2">
                  <div className="form-check pt-2 ms-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckChecked"
                    />
                  </div>

                  <td>2</td>
                  <td>Transport</td>
                  <td>Abay Logistics</td>
                  <td>Bastian (9345356732)</td>
                  <td>35</td>
                  <td>20</td>
                  <td>Ethiopia</td>
                  <td>Africa</td>
                  <td>Permanent</td>
                  <td>Active</td>
                  <div className="option-dropdown dropdown p-2">
                    <a
                      href=""
                      className=""
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img src={ic_options} alt="" />
                    </a>
                    <ul className="dropdown-menu ">
                      <li>
                        <button className="dropdown-item" type="button">
                          Edit{" "}
                          <img
                            src={dropdown_arrow_blue}
                            className="arrowvehicle"
                            alt=""
                          />
                        </button>
                      </li>
                      <li>
                        <Link
                          to="/ViewDetails"
                          className="text-decoration-none"
                        >
                          <button className="dropdown-item " type="button">
                            View Details
                          </button>
                        </Link>
                      </li>

                      <li>
                        <button className="dropdown-item" type="button">
                          Change Over
                        </button>
                      </li>
                      <li>
                        <button className="dropdown-item" type="button">
                          Block
                        </button>
                      </li>
                    </ul>
                  </div>
                </tr>
                <tr className="Rowbgcolor p-2">
                  <div className="form-check pt-2 ms-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckChecked"
                    />
                  </div>
                  <td>3</td>
                  <td>Transport</td>
                  <td>Abay Logistics</td>
                  <td>Bastian (9345356732)</td>
                  <td>35</td>
                  <td>20</td>
                  <td>Ethiopia</td>
                  <td>Africa</td>
                  <td>Permanent</td>
                  <td>Active</td>
                  <div className="option-dropdown dropdown p-2">
                    <a
                      href=""
                      className=""
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img src={ic_options} alt="" />
                    </a>
                    <ul className="dropdown-menu ">
                      <li>
                        <button className="dropdown-item" type="button">
                          Edit{" "}
                          <img
                            src={dropdown_arrow_blue}
                            className="arrowvehicle"
                            alt=""
                          />
                        </button>
                      </li>
                      <li>
                        <Link
                          to="/ViewDetails"
                          className="text-decoration-none"
                        >
                          <button className="dropdown-item " type="button">
                            View Details
                          </button>
                        </Link>
                      </li>

                      <li>
                        <button className="dropdown-item" type="button">
                          Change Over
                        </button>
                      </li>
                      <li>
                        <button className="dropdown-item" type="button">
                          Block
                        </button>
                      </li>
                    </ul>
                  </div>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
        <div className="fix-bottom-bar d-flex justify-content-between align-items-center mt-4">
          <div className="left"></div>
          <div className="right">
            <a href="">
              <button type="submit" className="cancel-btn">
                Cancel
              </button>
            </a>
            <Link to="#">
              <button type="submit" className="continue-btn" onClick={handlefullscreen}>
                Offer Vehicle
              </button>
            </Link>
            <Link to="#">
              <button type="submit" className="continue-btn">
                Assign
              </button>
            </Link>
          </div>
        </div>
      </div>

      <Modal show={fullscreenpopup} fullscreen={fullscreen} onHide={() => setFullscreenpopup(false)} className="popup-offer-vehicle">
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="card-heading">Offer Vehicle</h5>
          </Modal.Title>
        </Modal.Header>
        <hr />

        <Modal.Body>
          <section className="offer-vehicles">
            <div className="row">
              <div className="col-lg-3 col-md-6 ">
                <div className="top-item mt-2">
                  <label className="" style={{ color: "#66739B" }}>
                    Upload Vehicle Images
                  </label>
                  <div className="border-block ">
                    <div className="uploading">
                      <img src={ic_vehicle} alt="" />
                      <p className="m-0 mt-3 drag-drop">
                        Drag & Drop files to upload
                      </p>
                      <p className="drag-drop">OR</p>
                      <a href="#" style={{ color: "#4D9DE7" }}>
                        Browse Files
                      </a>
                    </div>
                  </div>
                </div>
                <div className="uploaded_items d-flex mt-4">
                  <div className="item_list">
                    <img
                      className="vehicle_img"
                      src={package_image_01}
                      alt=""
                    />
                    <a href="#" className="close_item">
                      <img src={circle_cross} alt="" />
                    </a>
                  </div>
                  <div className="item_list">
                    <img
                      className="vehicle_img"
                      src={package_image_02}
                      alt=""
                    />
                    <a href="#" className="close_item">
                      <img src={circle_cross} alt="" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-9 col-md-6">
                <div className="row">
                  <div className="col-lg-4 ">
                    <div className="form-group mt-2">
                      <label htmlFor="exampleInputEmail1">
                        Reference Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="form-group mt-2">
                      <label htmlFor="exampleInputEmail1">Plate Number</label>
                      <input
                        type="search"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="form-group mt-2">
                      <label htmlFor="exampleInputEmail1">Model</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">Vehicle Type</label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                      >
                        <option selected></option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">Year of Manufacture</label>
                      <input
                        type="month"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">Chassis Number</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">Motor Number</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">Gross Weight</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">Vehicle Load Capacity in Quintal</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">Current km</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">Driver Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">Driver Driving License</label>
                      <input
                        type="search"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <section className="main-vehicle-info owner-details mt-4">
            <div className="content">
              <div className="row">
                <div className="col-12">
                  <h5 className="card-heading">Vehicle Owner Details</h5>
                  <hr />

                  <div className="row">
                    <div className="col-lg-3 col-md-6">
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">Full Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">Email</label>
                        <input
                          type="email"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">Phone Number</label>
                        <input
                          type="tel"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">Region</label>
                        <input
                          type="tel"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                  <div className="col-lg-3 col-md-6">
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">Zone</label>
                        <input
                          type="tel"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">Woreda</label>
                        <input
                          type="tel"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">House No.</label>
                        <input
                          type="tel"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">P.O.Box</label>
                        <input
                          type="tel"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>

                    
                    <div className="col-lg-3 col-md-6">
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">Country</label>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                        >
                          <option selected></option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          <div className="fix-bottom-bar d-flex justify-content-between align-items-center mt-4">
            <div className="left"></div>
            <div className="right">
              <a href="" >
                <button
                  type='submit'
                  className="cancel-btn"
                >
                  Cancel
                </button>

              </a>
              <Link to="/OfferVehicles" >
                <button
                  type='submit'
                  className="continue-btn"
                >
                  Add Vehicle
                </button>

              </Link>
              {/* <div className="login-btn">
                <button
                  type="submit"
                  
                  className="btn  col-sm-12 col-md-12 col-lg-12"
                >
                  <span style={{ color: "white" }}> Continue</span>
                </button>
              </div> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </main>
  );
};

export default SelectVehicleSpecificationsList;
