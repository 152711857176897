import React, { useEffect, useState } from 'react'
import ApiConfig from '../api/ApiConfig';
import ic_upload_document from "../assets/images/AddVehicles/ic_upload_document.svg";
export default function DragAndDropForVehiclesInsurance({ setFile, insuranceFile }) {

    let id = "DropZone2"
    useEffect(() => {
        initDragAndDrop();
    }, [])
    const initDragAndDrop = () => {
        var dropZone = document.getElementById(id);
        // Optional.   Show the copy icon when dragging over.  Seems to only work for chrome.
        dropZone && dropZone.addEventListener('dragover', function (e) {
            e.stopPropagation();
            e.preventDefault();
            e.dataTransfer.dropEffect = 'copy';
        });
        dropZone && dropZone.addEventListener('drop', drop);
    }
    function drop(e) {
        e.stopPropagation();
        e.preventDefault();
        let file = "";
        if (e.target && e.target.files) {
            file = e.target.files[0];
        } else {
            file = e.dataTransfer.files[0]
        }
        setFile(file)
    }

    return (
        <div className="border-block " id={id}>
            <div className="uploading">
                <img src={insuranceFile && insuranceFile.name ? URL.createObjectURL(insuranceFile) : insuranceFile && insuranceFile.length ? ApiConfig.BASE_URL_FOR_IMAGES + insuranceFile : ic_upload_document} alt="" width={100} height={100} />
                <div>
                    <label className="m-0 mt-3 drag-drop">
                        Drag & Drop files to upload
                    </label><br />
                    <label className="drag-drop">OR</label>
                </div>
                <a href="#" style={{ color: "#4D9DE7" }} onClick={(e) => {
                    e.preventDefault();
                    document.getElementById("uploadVehicleImages2").click()
                }}>
                    Browse Files
                </a>
                <input onChange={drop} accept="image/png, image/gif, image/jpeg" id={"uploadVehicleImages2"} type="file" name="vehicle_images" style={{ display: "none" }} />
            </div>
        </div>
    )
}
