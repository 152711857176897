import React, { useContext, useState } from "react";
import ic_trucks from "../../assets/images/megaoffer/ic_trucks.svg";
import ic_remove from "../../assets/images/megaoffer/ic_remove.svg";
import ic_options from "../../assets/images/megaoffer/ic_options.svg";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/user/AppContext";
const VehicalOffRecived = () => {
  const { mainClass } = useContext(AppContext);
  const [show, setShow] = useState(false);

  return (
    <React.Fragment>
      {/* <Sidebar/>
         <AbhayHeader/> */}
      <div
        className={`section ${mainClass}`}
        id="cxMain"
        style={{ marginLeft: "70px", transition: "all 0.5s" }}
      >
        <div className=" container-fluid">
          <div className="row ">
            <div className=" mt-4 ">
              <div className="">
                {/* first card  */}
                <div className="row">
                  <div className="col-lg-6  mb-3 ">
                    <div className="card    ">
                      <div className="p-1">
                        <div className="">
                          <div className="">
                            <div className="d-flex justify-content-between">
                              <div className="d-flex">
                                <img src={ic_trucks} className="card-rsponsive-img" alt="" />
                                <h5 className="my-free p-1">My Load</h5>
                              </div>
                              <div className="ongoing-dropdown dropdown ">
                                <a
                                  href=""
                                  className=""
                                  data-bs-toggle="dropdown"
                                  aria-expanded="true"
                                >
                                  <img className="" src={ic_options} alt="" />
                                </a>
                                <ul className="dropdown-menu ">
                                  <li>
                                    <Link
                                      to="/ViewDetails"
                                      className="text-decoration-none"
                                    >
                                      <button
                                        className="dropdown-item "
                                        type="button"
                                      >
                                        View Details
                                      </button>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="/SelectReportTypes"
                                      className="text-decoration-none"
                                    >
                                      <button
                                        className="dropdown-item "
                                        type="button"
                                      >
                                        Reports
                                      </button>
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>

                            <div className="row ms-5 me-2 responsive-card-body">
                              <div className="text-start">
                                <dd className="asq">Ibris Brisbane - Ann St</dd>
                                <p></p>
                              </div>

                              <div className="d-flex rsponsive-card-data justify-content-between">
                                <dd>Ref. No.</dd>
                                <dt>UFSKE 4353544</dt>
                              </div>
                              <div className="d-flex justify-content-between rsponsive-card-data">
                                <dd>Vehicle Owner</dd>
                                <dt>Joshua Kimm…</dt>
                              </div>
                              <div className="d-flex  justify-content-between rsponsive-card-data">
                                <dd>Vehicle Type</dd>
                                <dt>Truck-Trailer</dt>
                              </div>
                              <div className="d-flex justify-content-between rsponsive-card-data">
                                <dd>Model</dd>
                                <dt>Eichet Pro 110XP</dt>
                              </div>
                              <div className="d-flex justify-content-between rsponsive-card-data">
                                <dd>Vehicle Capacity</dd>
                                <dt>20 Quintals</dt>
                              </div>
                              <div className="d-flex justify-content-between rsponsive-card-data">
                                <dd>Total Price</dd>
                                <dt>200 USD</dt>
                              </div>

                              <div className="btn ">
                                <button
                                  type="button"
                                  className="btn btn-outline-light text-center btnre "
                                  id="bids"
                                >
                                  <img
                                    className="pt-2"
                                    src={ic_remove}
                                    alt=""
                                  />
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-outline-light  btnre "
                                  data-toggle="modal"
                                  data-target="#myModal"
                                  id="bids"
                                  onClick={() => setShow(!show)}
                                >
                                  Accept
                                </button>
                              </div>
                            </div>
                            <Modal responsive-md
                              className="modal-md"
                              id="myModal"
                              role="dialog"
                              show={show}
                              size="sm"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                            >
                              <div>
                                <p className="text-center popup-heading mt-4">
                                  Are you sure you want to approve this offer?
                                </p>
                              </div>
                              <div className="btn-popup-modal justify-content-evenly  d-flex">
                                <a href="" className="m-1">
                                  <button className="btn btn-outline-info btnre ">
                                    Reject
                                  </button>
                                </a>

                                <a href="" className="m-1">
                                  <button className="btn btn-outline-info btnre ">
                                    Approve
                                  </button>
                                </a>
                              </div>
                            </Modal>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mb-3  ">
                    <div className="card    ">
                      <div className="p-1">
                        <div className="">
                          <div className="">
                            <div className="d-flex justify-content-between">
                              <div className="d-flex">
                                <img src={ic_trucks} className="card-rsponsive-img" alt="" />
                                <h5 className="my-free p-1">My Load</h5>
                              </div>
                              <div className="ongoing-dropdown dropdown ">
                                <a
                                  href=""
                                  className=""
                                  data-bs-toggle="dropdown"
                                  aria-expanded="true"
                                >
                                  <img className="" src={ic_options} alt="" />
                                </a>
                                <ul className="dropdown-menu ">
                                  <li>
                                    <Link
                                      to="/ViewDetails"
                                      className="text-decoration-none"
                                    >
                                      <button
                                        className="dropdown-item "
                                        type="button"
                                      >
                                        View Details
                                      </button>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="/SelectReportTypes"
                                      className="text-decoration-none"
                                    >
                                      <button
                                        className="dropdown-item "
                                        type="button"
                                      >
                                        Reports
                                      </button>
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>

                            <div className="row ms-5 me-2 responsive-card-body">
                              <div className="text-start">
                                <dd className="asq">Ibris Brisbane - Ann St</dd>
                                <p></p>
                              </div>

                              <div className="d-flex justify-content-between rsponsive-card-data">
                                <dd>Ref. No.</dd>
                                <dt>UFSKE 4353544</dt>
                              </div>
                              <div className="d-flex justify-content-between rsponsive-card-data">
                                <dd>Vehicle Owner</dd>
                                <dt>Joshua Kimm…</dt>
                              </div>
                              <div className="d-flex justify-content-between rsponsive-card-data">
                                <dd>Vehicle Type</dd>
                                <dt>Truck-Trailer</dt>
                              </div>
                              <div className="d-flex justify-content-between rsponsive-card-data">
                                <dd>Model</dd>
                                <dt>Eichet Pro 110XP</dt>
                              </div>
                              <div className="d-flex justify-content-between rsponsive-card-data">
                                <dd>Vehicle Capacity</dd>
                                <dt>20 Quintals</dt>
                              </div>
                              <div className="d-flex justify-content-between rsponsive-card-data">
                                <dd>Total Price</dd>
                                <dt>200 USD</dt>
                              </div>

                              <div className="btn ">
                                <button
                                  type="button"
                                  className="btn btn-outline-light text-center"
                                  id="bids"
                                >
                                  <img
                                    className="pt-2"
                                    src={ic_remove}
                                    alt=""
                                  />
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-outline-light  "
                                  data-toggle="modal"
                                  data-target="#myModal"
                                  id="bids"
                                  onClick={() => setShow(!show)}
                                >
                                  Accept
                                </button>
                              </div>
                            </div>
                            <Modal
                              className="modal-md"
                              id="myModal"
                              role="dialog"
                              show={show}
                              size="sm"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                            >
                              <div>
                                <p className="text-center popup-heading mt-4">
                                  Are you sure you want to approve this offer?
                                </p>
                              </div>
                              <div className="btn-popup-modal justify-content-evenly  d-flex">
                                <a href="" className="m-1">
                                  <button
                                    type="button"
                                    className="btn  btnre "
                                    id="bids"
                                  >
                                    Reject
                                  </button>
                                </a>

                                <a href="" className="m-1">
                                  <button
                                    type="button"
                                    className="btn  btnre "
                                    id="bids"
                                  >
                                    Approve
                                  </button>
                                </a>
                              </div>
                            </Modal>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mb-3 ">
                    <div className="card    ">
                      <div className="p-1">
                        <div className="">
                          <div className="">
                            <div className="d-flex justify-content-between">
                              <div className="d-flex">
                                <img src={ic_trucks} className="card-rsponsive-img" alt="" />
                                <h5 className="my-free p-1">My Load</h5>
                              </div>
                              <div className="ongoing-dropdown dropdown ">
                                <a
                                  href=""
                                  className=""
                                  data-bs-toggle="dropdown"
                                  aria-expanded="true"
                                >
                                  <img className="" src={ic_options} alt="" />
                                </a>
                                <ul className="dropdown-menu ">
                                  <li>
                                    <Link
                                      to="/ViewDetails"
                                      className="text-decoration-none"
                                    >
                                      <button
                                        className="dropdown-item "
                                        type="button"
                                      >
                                        View Details
                                      </button>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="/SelectReportTypes"
                                      className="text-decoration-none"
                                    >
                                      <button
                                        className="dropdown-item "
                                        type="button"
                                      >
                                        Reports
                                      </button>
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>

                            <div className="row ms-5 me-2 responsive-card-body">
                              <div className="text-start">
                                <dd className="asq">Ibris Brisbane - Ann St</dd>
                                <p></p>
                              </div>

                              <div className="d-flex justify-content-between rsponsive-card-data">
                                <dd>Ref. No.</dd>
                                <dt>UFSKE 4353544</dt>
                              </div>
                              <div className="d-flex justify-content-between rsponsive-card-data">
                                <dd>Vehicle Owner</dd>
                                <dt>Joshua Kimm…</dt>
                              </div>
                              <div className="d-flex justify-content-between rsponsive-card-data">
                                <dd>Vehicle Type</dd>
                                <dt>Truck-Trailer</dt>
                              </div>
                              <div className="d-flex justify-content-between rsponsive-card-data">
                                <dd>Model</dd>
                                <dt>Eichet Pro 110XP</dt>
                              </div>
                              <div className="d-flex justify-content-between rsponsive-card-data">
                                <dd>Vehicle Capacity</dd>
                                <dt>20 Quintals</dt>
                              </div>
                              <div className="d-flex justify-content-between rsponsive-card-data">
                                <dd>Total Price</dd>
                                <dt>200 USD</dt>
                              </div>

                              <div className="btn ">
                                <button
                                  type="button"
                                  className="btn btn-outline-light text-center"
                                  id="bids"
                                >
                                  <img
                                    className="pt-2"
                                    src={ic_remove}
                                    alt=""
                                  />
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-outline-light  "
                                  data-toggle="modal"
                                  data-target="#myModal"
                                  id="bids"
                                  onClick={() => setShow(!show)}
                                >
                                  Accept
                                </button>
                              </div>
                            </div>
                            <Modal
                              className="modal-md"
                              id="myModal"
                              role="dialog"
                              show={show}
                              size="sm"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                            >
                              <div>
                                <p className="text-center popup-heading mt-4">
                                  Are you sure you want to approve this offer?
                                </p>
                              </div>
                              <div className="btn-popup-modal justify-content-evenly  d-flex">
                                <a href="" className="m-1">
                                  <button
                                    type="button"
                                    className="btn  btnre "
                                    id="bids"
                                  >
                                    Reject
                                  </button>
                                </a>

                                <a href="" className="m-1">
                                  <button
                                    type="button"
                                    className="btn  btnre "
                                    id="bids"
                                  >
                                    Approve
                                  </button>
                                </a>
                              </div>
                            </Modal>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default VehicalOffRecived;
