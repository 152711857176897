import React, { useContext } from "react";
import ic_trucks from "../../assets/images/megaoffer/ic_trucks.svg";
import ic_options from "../../assets/images/megaoffer/ic_options.svg";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/user/AppContext";
const MarketPlaVehicleOff = () => {

  const { mainClass } = useContext(AppContext);

  return (
    <React.Fragment>
      {/* <Sidebar/>
         <AbhayHeader/> */}
      <div className={`section-body  ${mainClass}`} id="cxMain">
        <div className="container-fluid">
          <div className="row">
            <div className="cards ">
              <div className="row " >
                {/* first card  */}
                <div className="col-lg-6  mb-3 " >
                  <div className="card    ">
                    <div className="p-1">
                      <div className="d-flex justify-content-between">
                        <div className="d-flex justify-content-between">
                          <img src={ic_trucks} alt="" className="card-rsponsive-img" />
                          <h5 className="my-free ms-3">My Load</h5>
                        </div>

                        <div className="ongoing-dropdown dropdown ">
                          <a
                            href=""
                            className=""
                            data-bs-toggle="dropdown"
                            aria-expanded="true"

                          >
                            <img className="" src={ic_options} alt="" />
                          </a>
                          <ul className="dropdown-menu ">
                            <li>
                              <Link to="/ViewDetails" className="text-decoration-none">
                                <button className="dropdown-item " type="button">
                                  View Details
                                </button>
                              </Link>

                            </li>
                            <li>
                              <Link to="/SelectReportTypes" className="text-decoration-none">
                                <button className="dropdown-item " type="button">
                                  Reports
                                </button>
                              </Link>

                            </li>
                          </ul>

                        </div>
                      </div>
                      <div className="row ms-5 me-2 responsive-card-body">
                        <div className="text-start">
                          <dd className="asq">Ibris Brisbane - Ann St</dd>
                          <p></p>
                        </div>
                        <div className="d-flex justify-content-between rsponsive-card-data">
                          <dd>Ref. No.</dd>
                          <dt>UFSKE 4353544</dt>
                        </div>
                        <div className="d-flex justify-content-between rsponsive-card-data ">
                          <dd>Vehicle Owner</dd>
                          <dt>Joshua Kimm…</dt>
                        </div>
                        <div className="d-flex justify-content-between rsponsive-card-data ">
                          <dd>Vehical Type</dd>
                          <dt>Truck-Trailer</dt>
                        </div>
                        <div className="d-flex justify-content-between rsponsive-card-data">
                          <dd>Model</dd>
                          <dt>Eichet Pro 110XP</dt>
                        </div>
                        <div className="d-flex justify-content-between rsponsive-card-data">
                          <dd>vehicle Capacity</dd>
                          <dt>20 ton.</dt>
                        </div>
                        <div className="btnVehicle text-center ">
                          <div className="btn text-center">
                            <Link to="/TruckofferAuction">
                              <button

                                type="button"
                                className="btn btn-outline-light"
                                id="bids"

                                data-toggle="modal"
                                data-target="#exampleModalCenter"
                              >
                                Go To Auction
                              </button>
                            </Link>

                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>


                {/* THIRD CARD  */}
                <div className="col-lg-6   mb-3 " >
                  <div className="card    ">
                    <div className="p-1">
                      <div className="d-flex justify-content-between">
                        <div className="d-flex justify-content-between">
                          <img src={ic_trucks} alt="" className="card-rsponsive-img" />
                          <h5 className="my-free ms-3">My Load</h5>
                        </div>

                        <div className="ongoing-dropdown dropdown ">
                          <a
                            href=""
                            className=""
                            data-bs-toggle="dropdown"
                            aria-expanded="true"

                          >
                            <img className="" src={ic_options} alt="" />
                          </a>
                          <ul className="dropdown-menu ">
                            <li>
                              <Link to="/ViewDetails" className="text-decoration-none">
                                <button className="dropdown-item " type="button">
                                  View Details
                                </button>
                              </Link>

                            </li>
                            <li>
                              <Link to="/SelectReportTypes" className="text-decoration-none">
                                <button className="dropdown-item " type="button">
                                  Reports
                                </button>
                              </Link>


                            </li>
                          </ul>

                        </div>
                      </div>
                      <div className="row ms-5 me-2 responsive-card-body">
                        <div className="text-start">
                          <dd className="asq">Ibris Brisbane - Ann St</dd>
                          <p></p>
                        </div>
                        <div className="d-flex justify-content-between rsponsive-card-data">
                          <dd>Ref. No.</dd>
                          <dt>UFSKE 4353544</dt>
                        </div>
                        <div className="d-flex justify-content-between rsponsive-card-data">
                          <dd>Vehicle Owner</dd>
                          <dt>Joshua Kimm…</dt>
                        </div>
                        <div className="d-flex justify-content-between rsponsive-card-data">
                          <dd>Vehical Type</dd>
                          <dt>Truck-Trailer</dt>
                        </div>
                        <div className="d-flex justify-content-between rsponsive-card-data">
                          <dd>Model</dd>
                          <dt>Eichet Pro 110XP</dt>
                        </div>
                        <div className="d-flex justify-content-between rsponsive-card-data">
                          <dd>vehicle Capacity</dd>
                          <dt>20 ton.</dt>
                        </div>
                        <div className="btnVehicle text-center ">
                          <div className="btn text-center">

                            <Link to="/TruckofferAuction">
                              <button

                                type="button"
                                className="btn btn-outline-light"
                                id="bids"

                                data-toggle="modal"
                                data-target="#exampleModalCenter"
                              >
                                Go To Auction
                              </button>
                            </Link>

                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MarketPlaVehicleOff;
