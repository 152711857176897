import React, { useContext, useEffect, useState } from "react";
import Abay_logo from "../assets/images/header/Abay_logo.png";
import ic_notification from "../assets/images/header/ic_notification.svg";
import { Link } from "react-router-dom";
import ic_options from "../assets/images/Trasnvehicle/ic_options.svg";
import dropdown_arrow_blue from "../assets/images/dropdown_arrow_blue.svg";
import profile_img from "../assets/images/header/Abaylogo.png";
import user_2 from "../assets/images/header/ChangeDriver/user_2.svg";
import ic_contact_us from "../assets/images/header/Profile/ic_contact_us.svg";
import right_arrow from "../assets/images/header/Profile/right_arrow.svg";
import ic_report from "../assets/images/header/Profile/ic_report.svg";
import ic_settings from "../assets/images/header/Profile/ic_settings.svg";
import Privacy from "../assets/images/header/Profile/Privacy.svg";
import ic_legal from "../assets/images/header/Profile/ic_legal.svg";
import ic_logout from "../assets/images/header/Profile/ic_logout.svg";
import { AppContext } from "../context/user/AppContext";
import Barloader from "./BarLoader";
import ic_profile from "../assets/images/line_user-3.svg";

import ApiConfig from "../api/ApiConfig";
import { postWithAuthCallWithErrorResponse } from "../api/ApiServices";
const AbhayHeader = ({ setLoggedIn }) => {
  const [state, setState] = useState(false);
  const [close, setClose] = useState(false);
  const [pin, setPin] = useState(false);
  const [btnclose, setBtnClose] = useState(false);
  const [notification, setNotification] = useState([]);

  const { ApiCaaling, profileDetails, customerData } = useContext(AppContext);

  const [responsiveSidebar, setResponsiveSidebar] = useState(false);
  const logout = (e) => {
    e.preventDefault();
    localStorage.clear();
    setLoggedIn(false);
  };
  function handleClick() {
    setState(!state);
  }
  function handleNotif() {
    setPin(!pin);
  }
  function handleClose() {
    setBtnClose(!btnclose);
  }
  useEffect(() => {
    if (ApiCaaling) {
      getNotification();

      window.scrollTo(0, 0);
    }
  }, [ApiCaaling]);

  const mobileSidebar = () => {};
  const getNotification = () => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.NOTIFICATION,
      JSON.stringify({ ...customerData })
    ).then((res) => {
      if (res.json.result) setNotification(res.json.notifications);
    });
  };

  return (
    <React.Fragment>
      <header className="ab-header">
        <nav
          className="navbar navbar-expand-md cx-navbar"
          style={{ height: "60px" }}
        >
          <div className="container-fluid ab-header-container">
            <Link to="/Dashboard" className="navbar-logo">
              <img
                src={Abay_logo}
                className="img-logo"
                alt="Abay-logo"
                onClick={mobileSidebar}
              />
            </Link>
            <div className="dash">Dashboard</div>
            <ul className="navbar-nav ms-auto ">
              <li className="nav-item ">
                <a
                  className="nav-link pe-auto"
                  aria-current="page"
                  href="#"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRightNotification"
                  aria-controls="offcanvasRightNotification"
                >
                  <img
                    // onClick={handleNotif}
                    src={ic_notification}
                    alt="notification-icon"
                  />
                  <div className="cx-alert">{notification.length}</div>
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#"
                  className="nav-link pe-auto"
                  aria-current="page"
                  // onClick={handleClick}
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRightAccount"
                  aria-controls="offcanvasRightAccount"
                >
                  <img
                    style={{
                      width: "35px",
                      height: "35px",
                      borderRadius: "50%",
                    }}
                    src={
                      ApiConfig.BASE_URL_FOR_IMAGES +
                      (profileDetails?.profile
                        ? profileDetails?.profile.user_profile_pic
                        : profile_img)
                    }
                    alt="Profileimg"
                  />
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </header>

      {/* //##-------------------------##
              Change Driver Start
         //##-------------------------## */}

      {/* ---------------------------------------------------------
                                Notification 
      ----------------------------------------------------------- */}

      <section>
        <div className="notification-out">
          <div
            className="Notification-content offcanvas offcanvas-end"
            tabIndex="-1"
            id="offcanvasRightNotification"
            aria-labelledby="offcanvasRightLabelNotification"
          >
            <div className="offcanvas-header justify-content-end">
              <button
                type="button"
                className="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>

            <div className=" Notification-header">
              <div className="d-flex  justify-content-between hr-line pb-2 mb-3">
                <p className="card-heading">Notifications</p>
                <div
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  className="me-2 text-decoration-none"
                >
                  <Link to="/Notifications" className="see">
                    See All
                  </Link>
                </div>
              </div>

              <div className="inside mt-2 mb-4">
                {notification.map((notify, index) => {
                  return (
                    <div className="d-flex justify-content-between hr-line pb-2 mb-3">
                      <div className=" me-2">
                        <p>{notify.notification_text}</p>
                        <label>{notify.notification_time}</label>
                      </div>
                      <div className="option-dropdown dropdown ">
                        <Link
                          to="#"
                          href=""
                          className=""
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img src={ic_options} alt="" />
                        </Link>
                        <ul className="dropdown-menu ">
                          <li>
                            <button className="dropdown-item" type="button">
                              Delete{" "}
                              <img
                                src={dropdown_arrow_blue}
                                className="orderConArow"
                                alt=""
                              />
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        {ApiCaaling && <Barloader id="loading" />}
      </section>

      {/* ----------------------------------------------------------------
                          profile
      ---------------------------------------------------------------
       */}

      <section className="">
        <div className="cx-account-out">
          <div className=" Account-content">
            <div className="Account-header">
              <div
                className="Account offcanvas offcanvas-end"
                tabIndex="-1"
                id="offcanvasRightAccount"
                aria-labelledby="offcanvasRightLabel"
              >
                <div className="offcanvas-header justify-content-end">
                  <button
                    type="button"
                    className="btn-close text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div
                  className="insidepro text-center "
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                >
                  <Link to="/profile">
                    <img
                      src={
                        ApiConfig.BASE_URL_FOR_IMAGES +
                        (profileDetails.profile && profileDetails.profile
                          ? profileDetails.profile.user_profile_pic
                          : profile_img)
                      }
                      alt="responsive-image"
                    />
                  </Link>
                  <p className="Header-profile-name mt-2" htmlFor="">
                    {profileDetails.profile && profileDetails.profile.user_name}
                  </p>
                  <label className="mb-4">
                    {profileDetails.profile &&
                      profileDetails.profile.user_email}
                  </label>
                </div>
                <ul
                  className="list-container account-list-style"
                  style={{ marginLeft: "2rem" }}
                >
                  <li className="list-unstyled mt-2">
                    <Link to="/profile" className="icon_arrow ">
                      <div
                        className="d-flex  promenu"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <img src={ic_profile} alt="" />
                        <h5 className="contnt  m-2">Profile</h5>
                      </div>
                      <img className="" src={right_arrow} alt="" />
                    </Link>
                  </li>
                  <li className="list-unstyled mt-2">
                    <Link to="/ContactUs" className="icon_arrow ">
                      <div
                        className="d-flex  promenu"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <img src={ic_contact_us} alt="" />
                        <h5 className="contnt  m-2">Contact Us </h5>
                      </div>
                      <img className="" src={right_arrow} alt="" />
                    </Link>
                  </li>
                  <li className="list-unstyled  mt-2">
                    <Link to="/Reports" className="icon_arrow">
                      <div
                        className="d-flex promenu"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <img className="" src={ic_report} alt="" />
                        <h5 className="contnt  m-2">Report</h5>
                      </div>
                      <img className=" " src={right_arrow} alt="" />
                    </Link>
                  </li>
                  <li className="list-unstyled  mt-2">
                    <Link to="/AdminSetting" className="icon_arrow">
                      <div
                        className="d-flex promenu"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <img className="" src={ic_settings} alt="" />
                        <h5 className="contnt  m-2">Settings</h5>
                      </div>
                      <img className=" " src={right_arrow} alt="" />
                    </Link>
                  </li>
                  <li className="list-unstyled  mt-2">
                    <Link to="/TermsAndConditions" className="icon_arrow">
                      <div
                        className="d-flex promenu"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <img className="" src={ic_legal} alt="" />
                        <h5 className="contnt  m-2">Terms & Conditions</h5>
                      </div>
                      <img className=" " src={right_arrow} alt="" />
                    </Link>
                  </li>
                  <li className="list-unstyled mt-2">
                    <Link to="#" className="icon_arrow">
                      <div
                        className="d-flex promenu"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <img className="" src={Privacy} alt="" />
                        <h5 className="contnt  m-2">Privacy Policy</h5>
                      </div>
                      <img className=" " src={right_arrow} alt="" />
                    </Link>
                  </li>
                  <li className="list-unstyled  mt-2">
                    <Link to="#" className="icon_arrow" onClick={logout}>
                      <div
                        className="d-flex promenu"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <img className="" src={ic_logout} alt="" />
                        <h5 className="contnt  m-2">Logout</h5>
                      </div>
                      <img className=" " src={right_arrow} alt="" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default AbhayHeader;
