import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/user/AppContext";
import { Link, useNavigate } from "react-router-dom";
import arrow from "../../assets/images/dropdown_arrow_blue.svg";
import ic_dropdown from "../../assets/images/ic_dropdown.svg";
import ic_dropUp_arrow from "../../assets/images/ic_dropUp_arrow.svg";

import Accordion from "react-bootstrap/Accordion";
import {
  postMultipartWithAuthCallWithErrorResponse,
  postWithAuthCallWithErrorResponse,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import swal from "sweetalert";

export const Reports = () => {
  const { mainClassB, customerData } = useContext(AppContext);
  const [reportTypes, setReportTypes] = useState([]);
  const [loading, setLoading] = useState(false);

  const [ShippersLists, setShippersLists] = useState([]);
  const [TransporterLists, setTransporterLists] = useState([]);
  const [reportConfigure, setReportConfigure] = useState({
    report_type: "",
    title: "",
    email: "",
    from_date: "",
    to_date: "",
    report_user_id: "",
  });
  const [errMsg, setErrMsg] = useState({
    report_type: "",
    title: "",
    email: "",
    from_date: "",
    to_date: "",
  });
  const onGenerateReport = () => {
    if (
      !reportConfigure.report_type === "" ||
      reportConfigure.title === "" ||
      reportConfigure.from_date === "" ||
      reportConfigure.to_date === ""
    ) {
      if (reportConfigure.report_type === "") {
        setErrMsg({ ...errMsg, report_type: "Report Type is required" });
      } else if (reportConfigure.title === "") {
        setErrMsg({ ...errMsg, title: "Please enter title" });
      } else if (reportConfigure.from_date === "") {
        setErrMsg({ ...errMsg, from_date: "Please select from date" });
      } else if (reportConfigure.to_date === "") {
        setErrMsg({ ...errMsg, to_date: "Please select to date" });
      }
    } else {
      let details = JSON.stringify({
        customer_id: customerData.customer_id,
        api_key: customerData.api_key,
        user_id: customerData.user_id,
        report_type: reportConfigure.report_type
          ? reportConfigure.report_type
          : 13,
        email: reportConfigure.email,
        title: reportConfigure.title,
        from_date: reportConfigure.from_date,
        to_date: reportConfigure.to_date,
        report_user_id: reportConfigure?.report_user_id
          ? reportConfigure?.report_user_id
          : null,
      });

      postMultipartWithAuthCallWithErrorResponse(ApiConfig.REPORT_ADD, details)
        .then((res) => {
          swal(res.json.message);
          // if (res.json.result) navigate("/AdminSetting");
        })
        .catch((err) => console.log(err));
    }
  };
  const getReportTypes = () => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.REPORT_TYPES,
      JSON.stringify({ ...customerData })
    )
      .then((res) => {
        if (res.json.result) {
          setReportTypes(res.json.report_types);
        }
      })
      .catch((err) => console.log(err));
  };

  const getTransporterList = () => {
    var uri = ApiConfig.TRANSPORTER_LIST;
    var jsonBody = JSON.stringify({
      ...customerData,
    });
    setLoading(true);
    postWithAuthCallWithErrorResponse(uri, jsonBody)
      .then((res) => {
        setLoading(false);
        if (res.json.result) {
          setTransporterLists(res.json.transporter_list);
        }
      })
      .catch((err) => {
        console.log("Ex :: ", err);
      });
  };

  const getShipperList = () => {
    setLoading(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.SHIPPER_LIST,
      JSON.stringify({ ...customerData })
    )
      .then((res) => {
        setLoading(false);
        if (res.json.result) {
          setShippersLists(res.json.shipper_list);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getTransporterList();
    getShipperList()
    getReportTypes();
  }, []);

  return (
    //  <main className={`main-section-reports-tab scroll-style ${mainClass} `}>

    //  </main>

    <main className={`main-section-reports ${mainClassB}`} id="cxMainB">
      {/* Report tabs */}

      {/*  */}

      <section className="report-body d-flex justify-content-center">
        <div className="reports-body-section ">
          <h4 className="reports-card-heading">Configure Reports</h4>
          <hr />
          <div className="form-group mt-3">
            <label htmlFor="exampleInputEmail1">Select Report Type</label>
            <select
              className="form-select"
              onChange={(e) => {
                setErrMsg({ ...errMsg, report_type: "" });
                setReportConfigure({
                  ...reportConfigure,
                  report_type: Number(e.target.value),
                });
              }}
            >
              {reportTypes?.map((option) => {
                return (
                  <optgroup label={option.parent_type}>
                    {option?.sub_type?.map((subOption) => {
                      return (
                        <option value={subOption.report_type_id}>
                          {subOption.report_type_name}
                        </option>
                      );
                    })}
                  </optgroup>
                );
              })}
            </select>
            {errMsg.report_type.length > 0 && (
              <div className="text-center text-danger">
                <span> {errMsg.report_type}</span>
              </div>
            )}
          </div>
          {reportConfigure?.report_type == 22 && (
            <div className="form-group mt-3">
              <label htmlFor="exampleInputEmail1">Select Transporter</label>
              <select
                className="form-select"
                onChange={(e) => {
                  // setErrMsg({ ...errMsg, report_user_id: "" });
                  setReportConfigure({
                    ...reportConfigure,
                    report_user_id: Number(e.target.value),
                  });
                }}
              >
                {TransporterLists?.map((option) => {
                  return (
                    <option value={option.user_id}>
                      {option.company_name}
                    </option>
                  );
                })}
              </select>
              {/* {errMsg.report_user_id.length > 0 && (
            <div className="text-center text-danger">
              <span> {errMsg.report_user_id}</span>
            </div>
          )} */}
            </div>
          )}
          {reportConfigure?.report_type == 23 && (
            <div className="form-group mt-3">
              <label htmlFor="exampleInputEmail1">Select Shipper</label>
              <select
                className="form-select"
                onChange={(e) => {
                  // setErrMsg({ ...errMsg, report_user_id: "" });
                  setReportConfigure({
                    ...reportConfigure,
                    report_user_id: Number(e.target.value),
                  });
                }}
              >
                {ShippersLists?.map((option) => {
                  return (
                    <option value={option.user_id}>
                      {option.company_name}
                    </option>
                  );
                })}
              </select>
              {/* {errMsg.report_user_id.length > 0 && (
            <div className="text-center text-danger">
              <span> {errMsg.report_user_id}</span>
            </div>
          )} */}
            </div>
          )}
          <div className="form-group mt-3">
            <label htmlFor="exampleInputEmail1">Enter Email</label>
            <input
              type="email"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              onChange={(e) => {
                setErrMsg({ ...errMsg, email: "" });
                setReportConfigure({
                  ...reportConfigure,
                  email: e.target.value,
                });
              }}
            />
            {errMsg.email.length < 0 && (
              <div className="text-center text-danger">
                <span> {errMsg.email}</span>
              </div>
            )}
          </div>
          <div className="form-group mt-3">
            <label htmlFor="exampleInputEmail1">Report Title</label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              onChange={(e) => {
                setErrMsg({ ...errMsg, title: "" });
                setReportConfigure({
                  ...reportConfigure,
                  title: e.target.value,
                });
              }}
            />
            {errMsg.title.length > 0 && (
              <div className="text-center text-danger">
                <span> {errMsg.title}</span>
              </div>
            )}
          </div>
          <div className="form-group mt-3">
            <label htmlFor="exampleInputEmail1">From Date</label>
            <input
              type="date"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              onChange={(e) => {
                setErrMsg({ ...errMsg, from_date: "" });
                setReportConfigure({
                  ...reportConfigure,
                  from_date: e.target.value,
                });
              }}
            />
            {errMsg.from_date.length > 0 && (
              <div className="text-center text-danger">
                <span> {errMsg.from_date}</span>
              </div>
            )}
          </div>
          <div className="form-group mt-3">
            <label htmlFor="exampleInputEmail1">To Date</label>
            <input
              type="date"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              onChange={(e) => {
                setErrMsg({ ...errMsg, to_date: "" });
                setReportConfigure({
                  ...reportConfigure,
                  to_date: e.target.value,
                });
              }}
            />
            {errMsg.to_date.length > 0 && (
              <div className="text-center text-danger">
                <span> {errMsg.to_date}</span>
              </div>
            )}
          </div>
          <div className="d-flex justify-content-center my-4">
            <button className="report-btn" onClick={onGenerateReport}>
              Generate
            </button>
          </div>
        </div>
      </section>
    </main>
  );
};
