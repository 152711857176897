import React from "react";
import { Link } from "react-router-dom";
// import "../../assets/css/AddVehicleOffer.scss";
import ic_calendar from "../../assets/images/ic_calendar.svg";
const AddVehicleOffer = () => {
  return (
    <main className="main-section">
      <div className="main-body">
        {/* vehicles details section */}
        <section className="vehicle-details common-body p-3 mt-4">
          <div className="row">
            <div className="col-12">
              <div className="top-content">
                <h5>Vehicle Details</h5>
                <hr />
              </div>
      
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group mt-4">
                    <label htmlFor="exampleInputEmail1">Select Vehicle</label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                    >
                      <option selected></option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group mt-4">
                    <label htmlFor="exampleFormControlTextarea1">Remarks</label>
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Availability section */}
        <section className="availability common-body p-3 mt-4">
          <div className="row">
            <div className="col-12">
              <div className="top-content">
                <h5>Availability </h5>
                <div className="form-check  check-time">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                    
                  />
                  <label className="form-check-label" htmlFor="flexCheckChecked">
                    Time
                  </label>
                </div>
                <hr />
              </div>
              <div className="row">
                <div className="col-md-2">
                  <div className="form-group mt-4">
                    <label htmlFor="exampleInputEmail1">
                      Select Available Dates
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                  </div>
                </div>
                <div className="col-md-1 calendear">
                  <img src={ic_calendar} alt="" />
                </div>
                <div className="col-md-3">
                  <div className="form-group mt-4">
                    <label htmlFor="exampleInputEmail1">
                      Select Available Time
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group mt-4">
                    <label htmlFor="exampleInputEmail1">Country</label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                    >
                      <option selected></option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group mt-4">
                    <label htmlFor="exampleInputEmail1">City</label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                    >
                      <option selected></option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group mt-4">
                    <label htmlFor="exampleFormControlTextarea1">
                      From Location
                    </label>
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group mt-4">
                    <label htmlFor="exampleFormControlTextarea1">To Location</label>
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="fix-bottom-bar d-flex justify-content-between align-items-center mt-4">
        <div className="left"></div>
        <div className="right">
          <a href="" >
          <button type="cancel" className="cancel-btn">
                Cancel
              </button>
          </a>
          <Link to="/VehiclesDetailsOffer" >
           
            <button type="submit" className="continue-btn">
            Send Offer
              </button>
          </Link>
        </div>
      </div>
    </main>
  );
};

export default AddVehicleOffer;
