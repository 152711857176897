import React, { useContext } from "react";
import dropdown_arrow_blue from "../../assets/images/dropdown_arrow_blue.svg";
import "../../assets/css/Driverlist.scss";
import ic_options from "../../assets/images/Trasnvehicle/ic_options.svg";
import ic_prev from '../../assets/images/Trasnvehicle/ic_prev.svg';
import ic_prev_active from '../../assets/images/Trasnvehicle/ic_prev_active.svg';
import ic_next from '../../assets/images/Trasnvehicle/ic_next.svg';
import ic_next_active from '../../assets/images/Trasnvehicle/ic_next_active.svg';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { AppContext } from "../../context/user/AppContext";
const OrderConfirmation = () => {
  const { mainClass } = useContext(AppContext);
  $(document).ready(function () {
    $('.dataList').DataTable(

    );
  });
  return (
    <React.Fragment>
      {/* <Sidebar/>
         <AbhayHeader/> */}
      <div className="vehicle cx-active-sidebar" id="cxMain">
        <div className="row">
          <div className="col-lg-12 col-md-12 ">
            <div className="d-flex  ">
              <div className="input-group   ">
                <div className="form-outline-md searchBox  ">
                  {/* <input
                    id="search-focus"
                    type="text"
                    placeholder="Search"
                    className="form-control searchText"
                  />
                  <a href="">
                    <img src={search} className="fa-search" alt="" />
                  </a> */}
                </div>
                <div className="PageSelect d-flex justify-content-center ">
                  {/* <label  className="p-2  EntPage" htmlFor="exampleFormControlSelect1">Entries per page</label> */}

                  {/* <from size="" className=" choise">
                    <select
                      className="form-select "
                      id="exampleFormControlSelect1"
                      aria-label=""
                    >
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="3">4</option>
                      <option value="3">5</option>
                      <option value="3">6</option>
                      <option value="3">7</option>
                      <option value="3">8</option>
                      <option value="3">9</option>
                      <option value="3">10</option>
                    </select>
                  </from> */}
                </div>

              </div>
            </div>
          </div>

          {(
            <div className="">

              <div className="">
                <div style={{ width: "100%", overflow: "auto" }}>
                  <table className="table-striped dataList" id="example" >
                    <thead >
                      <tr>
                        <th>S.No</th>
                        <th>Order Number</th>
                        <th>Company Name</th>
                        <th>Goods Name</th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr className="table-row mb-3">
                        <td>1</td>
                        <td id="orderNo">FEWER43543 </td>
                        <td id="Company">Name.com</td>
                        <td id="goodsNAM">groceries trailer</td>

                        <div className="option-dropdown dropdown p-2">
                          <a
                            href=""
                            className=""
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img src={ic_options} alt="" />
                          </a>
                          <ul className="dropdown-menu ">
                            <li>
                              <button className="dropdown-item" type="button">
                                View Order <img src={dropdown_arrow_blue} className="orderConArow" alt="" />
                              </button>
                            </li>

                          </ul>
                        </div>
                      </tr>
                      <tr className="table-row m-2">
                        <td>2</td>
                        <td id="orderNo">FEWER43543 </td>
                        <td id="Company">Name.com</td>
                        <td id="goodsNAM">groceries trailer</td>

                        <div className="option-dropdown dropdown p-2">
                          <a
                            href=""
                            className=""
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img src={ic_options} alt="" />
                          </a>
                          <ul className="dropdown-menu ">
                            <li>
                              <button className="dropdown-item" type="button">
                                View Order <img src={dropdown_arrow_blue} className="orderConArow" alt="" />
                              </button>
                            </li>

                          </ul>
                        </div>
                      </tr>


                    </tbody>
                  </table>
                </div>
              </div>
            </div>

          )}


          <div className="Vehiclelistpage">
            <a href="#" className="R-arow" > <img className="Larow" src={ic_prev} alt="" /> <img src={ic_prev_active} alt="" /> </a>
            <a href="#">1</a>
            <a href="#">2 </a>
            <a href="#">3</a>
            <a href="#">4</a>
            <a href="#" className="L-arow"><img className="Larow" src={ic_next} alt="" /> <img src={ic_next_active} alt="" />  </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OrderConfirmation;
