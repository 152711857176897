import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import dropdown_arrow_blue from "../../assets/images/dropdown_arrow_blue.svg";
import "../../assets/css/goodsoffer.scss";
import ic_add from "../../assets/images/Trasnvehicle/ic_add.svg";
import ic_import from "../../assets/images/Trasnvehicle/ic_import.svg";
import ic_export from "../../assets/images/Trasnvehicle/ic_export.svg";
import ic_options from "../../assets/images/Trasnvehicle/ic_options.svg";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { AppContext } from "../../context/user/AppContext";
const GoodsOffer = () => {
  const { mainClass } = useContext(AppContext);
  $(document).ready(function () {
    $(".dataList").DataTable();
  });
  return (
    <>
      {/* <Sidebar />
      <AbhayHeader /> */}
      <div
        className={`main-section-dashboard scroll-style  ${mainClass}`}
        id="cxMain"
      >
        <div className="row ">
          <div className=" ">
            <div className="d-flex ">
              <div className="input-group justify-content-between ">
                <div className=" mt-2">
                  <div className="form-outline-lg searchBox mt-0">
                    <div className="">
                      {/* <input
                        id="search-focus"
                        type="text"
                        placeholder="Search"
                        className="form-control searchText"
                      /> */}
                    </div>
                    {/* <a href="">
                      <img src={search} className="search-ic" alt="" />
                    </a> */}
                  </div>
                </div>
                {/* <div className="col-lg-4 p-0 mt-2">
                  <div className="PageSelect d-flex justify-content-center ">
                    <label
                      className="select EntPage "
                      htmlFor="exampleFormControlSelect1"
                    >
                      Entries per page
                    </label>

                    <from size="" className=" ">
                      <select
                        className="form-select"
                        id="exampleFormControlSelect1"
                        aria-label=""
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="3">4</option>
                        <option value="3">5</option>
                        <option value="3">6</option>
                        <option value="3">7</option>
                        <option value="3">8</option>
                        <option value="3">9</option>
                        <option value="3">10</option>
                      </select>
                    </from>
                  </div>
                </div> */}
                <div className=" mt-2">
                  <div className="  ">
                    <ul className="d-flex list-unstyled justify-content-between ">
                      <li className="nav-item icons me-3 pe-2">
                        <Link to="#">
                          <img
                            src={ic_export}
                            className="add  mb-2"
                            alt="send-load-icon"
                          />
                        </Link>
                      </li>
                      <li className="nav-item icons me-3 p-2 ">
                        <Link to="#">
                          <img
                            src={ic_import}
                            className="add"
                            alt="send-load-icon"
                          />
                        </Link>
                      </li>
                      <li className="nav-item icons p-2">
                        <Link to="/AddGoods">
                          <img
                            src={ic_add}
                            className="add"
                            alt="send-load-icon"
                          />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Table  */}

        <div className="">
          <div className="row">
            <div>
              <div
                className=""
                style={{
                  width: "100%",
                  overflow: "auto",
                  // marginBottom: "100px",
                }}
              >
                <table className="dataList table table-striped" id="dataList">
                  <thead>
                    <tr>
                      <th>
                        <label htmlFor="">S.No</label>
                      </th>
                      <th>
                        <label htmlFor="">Goods Name</label>
                      </th>
                      <th>
                        <label htmlFor="">Company Name</label>
                      </th>
                      <th>
                        <label htmlFor="">Cargo Type</label>
                      </th>
                      <th>
                        <label htmlFor="">Quantity</label>
                      </th>
                      <th>
                        <label htmlFor="">Estimated Arrival Time</label>
                      </th>
                      <th>
                        <label htmlFor="">Status</label>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="Rowbgcolor p-2">
                      <td>1</td>
                      <td>My Freight</td>
                      <td>Good & Services</td>
                      <td>Container with type</td>
                      <td>10 Quintals</td>
                      <td>22-Feb-2022, 10:30 AM</td>
                      <td>
                        {" "}
                        <label className="text-danger tablelink">
                          {" "}
                          Vehicle Request Sent
                        </label>
                      </td>
                      <div className="option-dropdown dropdown p-2">
                        <a
                          href=""
                          className=""
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img src={ic_options} alt="" />
                        </a>
                        <ul className="dropdown-menu ">
                          <li>
                            <button className="dropdown-item" type="button">
                              Edit{" "}
                              <img
                                src={dropdown_arrow_blue}
                                className="arrowvehicle"
                                alt=""
                              />
                            </button>
                          </li>
                          <li>
                            <Link
                              to="/ViewDetails"
                              className="text-decoration-none"
                            >
                              <button className="dropdown-item " type="button">
                                View Details
                              </button>
                            </Link>
                          </li>

                          <li>
                            <button className="dropdown-item" type="button">
                              Change Over
                            </button>
                          </li>
                          <li>
                            <button className="dropdown-item" type="button">
                              Block
                            </button>
                          </li>
                        </ul>
                      </div>
                    </tr>
                    <tr className="Rowbgcolor p-2">
                      <td>2</td>
                      <td>My Freight</td>
                      <td>Good & Services</td>
                      <td>Container with type</td>
                      <td>10 Quintals</td>
                      <td>22-Feb-2022, 10:30 AM</td>
                      <td className="text-primary">
                        {" "}
                        <a to="" className="tablelink">
                          See Vehicle Offers{" "}
                        </a>{" "}
                      </td>
                      <div className="option-dropdown dropdown p-2">
                        <a
                          href=""
                          className=""
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img src={ic_options} alt="" />
                        </a>
                        <ul className="dropdown-menu ">
                          <li>
                            <button className="dropdown-item" type="button">
                              Edit{" "}
                              <img
                                src={dropdown_arrow_blue}
                                className="arrowvehicle"
                                alt=""
                              />
                            </button>
                          </li>
                          <li>
                            <Link
                              to="/ViewDetails"
                              className="text-decoration-none"
                            >
                              <button className="dropdown-item " type="button">
                                View Details
                              </button>
                            </Link>
                          </li>

                          <li>
                            <button className="dropdown-item" type="button">
                              Change Over
                            </button>
                          </li>
                          <li>
                            <button className="dropdown-item" type="button">
                              Block
                            </button>
                          </li>
                        </ul>
                      </div>
                    </tr>
                    <tr className="Rowbgcolor p-2">
                      <td>3</td>
                      <td>My Freight</td>
                      <td>Good & Services</td>
                      <td>Container with type</td>
                      <td>10 Quintals</td>
                      <td>22-Feb-2022, 10:30 AM</td>
                      <td className=" ">
                        <label className="text-success tablelink">
                          {" "}
                          Approved
                        </label>
                      </td>
                      <div className="option-dropdown dropdown p-2">
                        <a
                          href=""
                          className=""
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img src={ic_options} alt="" />
                        </a>
                        <ul className="dropdown-menu ">
                          <li>
                            <button className="dropdown-item" type="button">
                              Edit{" "}
                              <img
                                src={dropdown_arrow_blue}
                                className="arrowvehicle"
                                alt=""
                              />
                            </button>
                          </li>
                          <li>
                            <button className="dropdown-item" type="button">
                              Change Over
                            </button>
                          </li>
                          <li>
                            <Link
                              to="/ViewDetails"
                              className="text-decoration-none"
                            >
                              <button className="dropdown-item " type="button">
                                View Details
                              </button>
                            </Link>
                          </li>

                          <li>
                            <button className="dropdown-item" type="button">
                              Block
                            </button>
                          </li>
                        </ul>
                      </div>
                    </tr>
                    <tr className="Rowbgcolor p-2">
                      <td>3</td>
                      <td>My Freight</td>
                      <td>Good & Services</td>
                      <td>Container with type</td>
                      <td>10 Quintals</td>
                      <td>22-Feb-2022, 10:30 AM</td>
                      <td className=" ">
                        <label className="text-success tablelink">
                          {" "}
                          Approved
                        </label>
                      </td>
                      <div className="option-dropdown dropdown p-2">
                        <a
                          href=""
                          className=""
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img src={ic_options} alt="" />
                        </a>
                        <ul className="dropdown-menu ">
                          <li>
                            <button className="dropdown-item" type="button">
                              Edit{" "}
                              <img
                                src={dropdown_arrow_blue}
                                className="arrowvehicle"
                                alt=""
                              />
                            </button>
                          </li>
                          <li>
                            <Link
                              to="/ViewDetails"
                              className="text-decoration-none"
                            >
                              <button className="dropdown-item " type="button">
                                View Details
                              </button>
                            </Link>
                          </li>

                          <li>
                            <button className="dropdown-item" type="button">
                              Change Over
                            </button>
                          </li>
                          <li>
                            <button className="dropdown-item" type="button">
                              Block
                            </button>
                          </li>
                        </ul>
                      </div>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* <div className="Vehiclelistpage">
            <a href="#" className="R-arow">
              {" "}
              <img className="Larow" src={ic_prev} alt="" />{" "}
              <img src={ic_prev_active} alt="" />{" "}
            </a>
            <a href="#">1</a>
            <a href="#">2 </a>
            <a href="#">3</a>
            <a href="#">4</a>
            <a href="#" className="L-arow">
              <img className="Larow" src={ic_next} alt="" />{" "}
              <img src={ic_next_active} alt="" />{" "}
            </a>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default GoodsOffer;
