import React, { useContext, useEffect, useState } from "react";
import ic_edit from "../../assets/images/ic_edit (2).svg";

import { Link, useNavigate } from "react-router-dom";
import "../../assets/css/style.scss";
import {
  postWithAuthCallWithErrorResponse,
  PostCallWithErrorResponse,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { AppContext } from "../../context/user/AppContext";
import Loader from "../../sharedComponent/Loader";
import swal from "sweetalert";
import { Modal } from "react-bootstrap";

const DriverApproval = () => {
  const navigate = useNavigate();

  const { customerData, setApiCalling } = useContext(AppContext);
  const [shipperPendingList, setShipperPendingList] = useState([]);

  const getShipperList = () => {
    var uri = ApiConfig.DRIVER_ADD_APPROVAL_LIST;
    setApiCalling(true);
    postWithAuthCallWithErrorResponse(uri, JSON.stringify({ ...customerData }))
      .then((res) => {
        setApiCalling(false);
        if (res.json.result) {
          setShipperPendingList(res.json.driver_list);
        }
      })
      .catch((err) => {
        console.log("Ex :: ", err);
      });
  };

  const shipperApprove = (id, status) => {
    setApiCalling(true);
    PostCallWithErrorResponse(ApiConfig.ADD_APPROVE_DRIVER, {
      ...customerData,
      driver_id: id,
      status: status === "pending" ? "active" : "removed",
    })
      .then((res) => {
        if (res.json.result) {
          swal(res.json.message);
          // navigate("/driverlist/");
          getShipperList();
          setApiCalling(false);
        } else {
          setApiCalling(false);

          swal(res.json.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const shipperReject = (id) => {
    setApiCalling(true);

    PostCallWithErrorResponse(ApiConfig.REJECT_USER, {
      ...customerData,
      pending_user_id: id,
    })
      .then((res) => {
        if (res.json.result) {
          swal(res.json.message);
          getShipperList();
          setApiCalling(false);
        } else {
          setApiCalling(false);
          swal(res.json.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getShipperList();
  }, []);

  return (
    <React.Fragment>
      <main>
        <div style={{ margin: "20px" }}>
          <div className=" all-request">
            <div className="  ">
              <div className="offerLoadsMainLists">
                <div className="mainLoadTable" style={{ overflow: "auto" }}>
                  <table className="dataList" style={{ minWidth: "900px" }}>
                    <thead>
                      <tr>
                        <th>
                          <label>S.No</label>
                        </th>
                        <th>
                          <label>Owner Name</label>
                        </th>
                        <th>
                          <label>Driver Name</label>
                        </th>
                        <th>
                          <label>Email Id</label>
                        </th>

                        <th>
                          <label>Licence Number</label>
                        </th>
                        <th>
                          <label>Nationality</label>
                        </th>
                        <th>
                          <label>Driver Status</label>
                        </th>
                        <th>
                          <label>Action</label>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {shipperPendingList ? (
                        shipperPendingList &&
                        shipperPendingList.map((shipperList, index) => {
                          return (
                            <tr
                              className="Rowbgcolor"
                              key={"shipperList" + index}
                            >
                              <td>
                                <label>{index + 1}</label>
                              </td>
                              <td>
                                <Link
                                  className="text-decoration-none"
                                  to={"/ShipperDetails/" + shipperList.user_id}
                                  state={shipperList}
                                >
                                  <label
                                    style={{
                                      cursor: "pointer",
                                      textTransform: "capitalize",
                                      color: "#38847C",
                                      textDecoration: "underline",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {shipperList.owner_name}
                                  </label>
                                </Link>
                              </td>
                              <td>
                                <label>{shipperList.driver_name}</label>
                              </td>
                              <td>
                                <label>{shipperList.email_id}</label>
                              </td>

                              <td>
                                <label>{shipperList.licence_number}</label>
                              </td>
                              <td>
                                <label>{shipperList.nationality}</label>
                              </td>
                              <td>
                                <label>{shipperList.driver_status}</label>
                              </td>

                              <td>
                                <label>
                                  <Link
                                    to="#"
                                    onClick={() =>
                                      shipperApprove(
                                        shipperList.driver_id,
                                        shipperList.driver_status
                                      )
                                    }
                                    className="regiBTN"
                                    id="accept"
                                  >
                                    Accept
                                    {/* <img src={cross_green} alt="" /> */}
                                  </Link>
                                  <Link
                                    to="#"
                                    onClick={() =>
                                      shipperReject(shipperList.driver_id)
                                    }
                                    className="regiBTN"
                                    id="reject"
                                  >
                                    {/* <img src={tick_green} alt="" /> */}
                                    Reject
                                  </Link>
                                </label>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <div
                          className="text-danger"
                          style={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          No Load Available
                        </div>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default DriverApproval;
