import React, { useContext, useState } from "react";
import ic_trucks from "../../assets/images/megaoffer/ic_trucks.svg";
import ic_remove from "../../assets/images/megaoffer/ic_remove.svg";
import ic_options from "../../assets/images/megaoffer/ic_options.svg";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/user/AppContext";
const VehicalRequest = () => {
  const { mainClass } = useContext(AppContext);
  return (
    <React.Fragment>
      {/* <Sidebar/>
         <AbhayHeader/> */}
      <div className={`section-body ${mainClass}`} id="cxMain">
        <div className="container-fluid">
          <div className="row ">
            <div className="col-lg-12 " >
              <div className="cards ">


                <div className="row">
                  <div className="cards ">
                    {/* first card  */}
                    <div className="row  ">



                      <div className="col-lg-6   mb-3 " >
                        <div className="card    ">
                          <div className="p-1">
                            <div className="d-flex justify-content-between">
                              <div className="d-flex justify-content-between">
                                <img src={ic_trucks} alt="" />
                                <h5 className="my-free ms-3">My Load</h5>
                              </div>

                              <div className="ongoing-dropdown dropdown ">
                                <a
                                  href=""
                                  className=""
                                  data-bs-toggle="dropdown"
                                  aria-expanded="true"

                                >
                                  <img src={ic_options} alt="" className="card-rsponsive-img" />
                                </a>
                                <ul className="dropdown-menu ">
                                  <li>
                                    <Link
                                      to="/ViewDetails"
                                      className="text-decoration-none"
                                    >
                                      <button
                                        className="dropdown-item "
                                        type="button"
                                      >
                                        View Details
                                      </button>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="/SelectReportTypes"
                                      className="text-decoration-none"
                                    >
                                      <button
                                        className="dropdown-item "
                                        type="button"
                                      >
                                        Reports
                                      </button>
                                    </Link>
                                  </li>
                                </ul>

                              </div>
                            </div>
                            <div className="row ms-5 me-2 responsive-card-body">
                              <div className="text-start">
                                <dd className="asq">Ibris Brisbane - Ann St</dd>
                                <p></p>
                              </div>
                              <div className="d-flex justify-content-between rsponsive-card-data">
                                <dd >Ref. No.</dd>
                                <dt >UFSKE 4353544</dt>
                              </div>
                              <div className="d-flex justify-content-between rsponsive-card-data">
                                <dd>Cargo Type</dd>
                                <dt>Vehicle</dt>
                              </div>
                              <div className="d-flex justify-content-between rsponsive-card-data ">
                                <dd>Capacity</dd>
                                <dt>1 ton.</dt>
                              </div>
                              <div className="d-flex justify-content-between rsponsive-card-data ">
                                <dd>Est. Start Dt.</dd>
                                <dt>27-Feb-2022</dt>
                              </div>
                              <div className="btn ">
                                <button
                                  type="button"
                                  className="btn btn-outline-light text-center"
                                  id="bids"
                                >
                                  <img className="pt-2" src={ic_remove} alt="" />
                                </button>
                                <Link to="/SelectVehiclelist">
                                  <button

                                    type="button"
                                    className="btn btn-outline-light"
                                    id="bids"

                                    data-toggle="modal"
                                    data-target="#exampleModalCenter"
                                  >
                                    Bid for Load
                                  </button>
                                </Link>

                              </div>

                            </div>
                          </div>
                        </div>
                      </div>

                      {/* SECOND CARD  */}

                      <div className="col-lg-6   mb-3 " >
                        <div className="card    ">
                          <div className="p-1">
                            <div className="d-flex justify-content-between">
                              <div className="d-flex justify-content-between">
                                <img src={ic_trucks} alt="" className="card-rsponsive-img" />
                                <h5 className="my-free ms-3">My Load</h5>
                              </div>

                              <div className="ongoing-dropdown dropdown ">
                                <a
                                  href=""
                                  className=""
                                  data-bs-toggle="dropdown"
                                  aria-expanded="true"

                                >
                                  <img className="" src={ic_options} alt="" />
                                </a>
                                <ul className="dropdown-menu ">
                                  <li>
                                    <Link
                                      to="/ViewDetails"
                                      className="text-decoration-none"
                                    >
                                      <button
                                        className="dropdown-item "
                                        type="button"
                                      >
                                        View Details
                                      </button>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="/SelectReportTypes"
                                      className="text-decoration-none"
                                    >
                                      <button
                                        className="dropdown-item "
                                        type="button"
                                      >
                                        Reports
                                      </button>
                                    </Link>
                                  </li>
                                </ul>

                              </div>
                            </div>
                            <div className="row ms-5 me-2 responsive-card-body">
                              <div className="text-start">
                                <dd className="asq">Ibris Brisbane - Ann St</dd>
                                <p></p>
                              </div>
                              <div className="d-flex justify-content-between rsponsive-card-data">
                                <dd >Ref. No.</dd>
                                <dt >UFSKE 4353544</dt>
                              </div>
                              <div className="d-flex justify-content-between rsponsive-card-data">
                                <dd>Cargo Type</dd>
                                <dt>Vehicle</dt>
                              </div>
                              <div className="d-flex justify-content-between rsponsive-card-data">
                                <dd>Capacity</dd>
                                <dt>1 ton.</dt>
                              </div>
                              <div className="d-flex justify-content-between rsponsive-card-data">
                                <dd>Est. Start Dt.</dd>
                                <dt>27-Feb-2022</dt>
                              </div>
                              <div
                                className="text-decoration-none text-center  "
                                id="approval"
                              >
                                <a to="">Waiting for approval</a>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default VehicalRequest;
