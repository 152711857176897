import React from 'react'

export default function Filters({ onSelect }) {
    const options = [
        { label: "Active", value: "active" },
        { label: "Inactive", value: "inactive" },
        { label: "With Contract", value: "yes" },
        {
            label: "Without Contract",
            value: "no",
            disabled: true,
            placeholder: "...",
        },
    ];
    return (
        <div className=' d-flex form-outline searchBox  m-0 ' >
            <label htmlFor="select" className='mr-4'>Filter</label>
            <select
                className='form-select'
                id='select'
                // value={selected}
                // onChange={(e) => setSelected(e.target.value)}
                // labelledBy="Select"
                onChange={(e) => onSelect(e.target.value)}
            >
                <option value="All">All</option>
                {options.map((option, index) => {
                    return (
                        <option value={option.value} key={"option" + index}>{option.label} </option>
                    )
                })}
            </select>
        </div>
    )
}
