import React, { useContext, useEffect, useState } from "react";
import profile from "../../assets/images/Skyscrapers.jpg";
import document_image from "../../assets/images/vehiclesDetails/document_image.png";
import ic_options_menu from "../../assets/images/ic_options_menu.svg";
import ic_options_menu_white from "../../assets/images/ic_options_menu_white.svg";
import { Link, useLocation, useParams } from "react-router-dom";
import dropdown_arrow_blue from "../../assets/images/dropdown_arrow_blue.svg";
import { AppContext } from "../../context/user/AppContext";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import Loader from "../../sharedComponent/Loader";
import ApiConfig from "../../api/ApiConfig";
import swal from "sweetalert";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
const TransporterDetails = () => {
  const params = useParams();
  let id = params.id;
  const pdfExportComponent = React.useRef(null);
  const [transporterDetails, setTransporterDetails] = useState({
    user_business_license_document: "",
  });
  const { mainClass, customerData } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getTransporterDetails();
  }, []);
  const getTransporterDetails = () => {
    setLoading(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.TRANSPORTER_DETAILS,
      JSON.stringify({ ...customerData, transporter_id: id })
    ).then((res) => {
      setLoading(false);
      if (res.json.result) setTransporterDetails(res.json.transporter_details);
    });
  };
  const blockTransporter = () => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.BLOCK_TRANSPORTER,
      JSON.stringify({ ...customerData, transporter_id: id })
    ).then((res) => {
      if (res.json.result) {
        swal(res.json.message);
        getTransporterDetails();
      }
    });
  };
  const unBlockTransporter = () => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.UNBLOCK_TRANSPORTER,
      JSON.stringify({ ...customerData, transporter_id: id })
    ).then((res) => {
      if (res.json.result) {
        swal(res.json.message);
        getTransporterDetails();
      }
    });
  };

  const exportPDFWithComponent = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

  const handleDownload = (imageUrl) => {
    const link = document.createElement("a");
    link.href = imageUrl;
    link.download = "image.jpg";
    link.click();
  };
  return (
    <main className={`main-profile-section ${mainClass}`} id="cxMain">
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <div className="prfile-body-section common-body">
          <div className="row">
            <div className="profile-banner">
              <div className="banner-img d-flex justify-content-between">
                <div></div>
                <div
                  className="option-dropdown dropdown "
                  id="transporterDetail-dropdown"
                >
                  <a href="" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src={ic_options_menu_white} alt="" />
                  </a>
                  <ul className="dropdown-menu ">
                    <li>
                      <Link
                        to={"/AddTransporter/" + transporterDetails.user_id}
                        className="text-decoration-none"
                      >
                        <button className="dropdown-item" type="button">
                          Edit{" "}
                        </button>
                      </Link>
                    </li>

                    {transporterDetails.user_status === "blocked" ? (
                      <li>
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={unBlockTransporter}
                        >
                          Unblock
                        </button>
                      </li>
                    ) : (
                      <li>
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={blockTransporter}
                        >
                          Block
                        </button>
                      </li>
                    )}
                  </ul>
                </div>
              </div>

              <div className="details-profile">
                <div className="profile-img">
                  <img
                    src={
                      transporterDetails.user_profile_pic
                        ? ApiConfig.BASE_URL_FOR_IMAGES +
                          transporterDetails.user_profile_pic
                        : profile
                    }
                    alt=""
                  />{" "}
                  <span className="name-profile mt-2">
                    {transporterDetails.company_name}
                  </span>
                </div>
                {/* BASIC INFO */}
                <PDFExport
                  PDFExport
                  ref={pdfExportComponent}
                  paperSize="auto"
                  margin={40}
                  fileName={`Report for ${new Date().getFullYear()}`}
                  author="KendoReact Team"
                >
                  <div className=" profile-basic-info row mt-4">
                    <div>
                      <div className="d-flex justify-content-between align-items-center">
                        <h5 className="card-heading">Basic Info</h5>

                        <button
                          className="btn p-1 my-2 expdf-btn"
                          onClick={exportPDFWithComponent}
                        >
                          Export Pdf
                        </button>
                      </div>
                    </div>
                    <hr />
                    <div className="col-lg-3 col-md-6 col-sm-6 mt-2">
                      <label htmlFor="">Company Name</label>
                      <p className="data">{transporterDetails.company_name}</p>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 mt-2">
                      <label htmlFor="">Company Type</label>
                      <p className="data">{transporterDetails.company_type}</p>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 mt-2">
                      <label htmlFor="">Total Fleet Size</label>
                      <p className="data">
                        {transporterDetails.user_fleet_size}
                      </p>
                    </div>
                  </div>
                  {/* CONTACT PORSON */}
                  <div className="profile-contact-person row mt-4">
                    <h5 className="card-heading">Contact Person</h5>
                    <hr />
                    <div className="col-lg-3 col-md-6 col-sm-6 mt-2">
                      <label htmlFor="">Name</label>
                      <p className="data">
                        {transporterDetails.contact_person}
                      </p>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 mt-2">
                      <label htmlFor="">Responsibility</label>
                      <p className="data">
                        {transporterDetails.user_responsibility}
                      </p>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 mt-2">
                      <label htmlFor="">Phone Number</label>
                      <p className="data">
                        {transporterDetails.contact_person_phone}
                      </p>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 mt-2">
                      <label htmlFor="">Email</label>
                      <p className="data">
                        {transporterDetails.contact_person_email}
                      </p>
                    </div>
                  </div>
                  {/* ADDRESS */}
                  <div className="profile-address row mt-4">
                    <h5 className="card-heading">Address</h5>
                    <hr />
                    <div className="col-lg-3 col-md-6 col-sm-6 mt-2">
                      <label htmlFor="">Country</label>
                      <p className="data">{transporterDetails.user_country}</p>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 mt-2">
                      <label htmlFor="">Region</label>
                      <p className="data">{transporterDetails.user_city}</p>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 mt-2">
                      <label htmlFor="">Phone </label>
                      <p className="data">{transporterDetails.user_mobile}</p>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 mt-2">
                      <label htmlFor="">Alternative Phone</label>
                      <p className="data">
                        {transporterDetails.user_alternate_phone}
                      </p>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 mt-2">
                      <label htmlFor="">Email</label>
                      <p className="data">{transporterDetails.user_email}</p>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 mt-2">
                      <label htmlFor="">P.O.Box</label>
                      <p className="data">
                        {transporterDetails.user_po_number}
                      </p>
                    </div>
                  </div>
                  {/* Documents */}
                  <div className="profile-contact-person row mt-4">
                    <h5 className="card-heading">Documents</h5>
                    <hr />
                    <div className="col-lg-3 col-md-4 col-sm-6 mt-2">
                      <label htmlFor="">Business License</label>
                      <div className="d-flex mx-1">
                        <img
                          src={
                            transporterDetails.user_business_license_document
                              ? ApiConfig.BASE_URL_FOR_IMAGES +
                                transporterDetails.user_business_license_document
                              : document_image
                          }
                          alt=""
                          style={{ width: "100%" }}
                        />
                        <div className="option-dropdown dropdown">
                          <a
                            href=""
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img
                              src={ic_options_menu}
                              className="dot-icon"
                              alt=""
                            />
                          </a>
                          <ul className="dropdown-menu ">
                            <li>
                              <button
                                onClick={() => {
                                  handleDownload(
                                    ApiConfig.BASE_URL_FOR_IMAGES +
                                      transporterDetails.user_business_license_document
                                  );
                                }}
                                className="dropdown-item"
                                type="button"
                              >
                                Download
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mt-2">
                      <label htmlFor="">Grade Certificate</label>
                      <div className=" d-flex mx-1">
                        <img
                          src={
                            transporterDetails.user_grade_certificate_document
                              ? ApiConfig.BASE_URL_FOR_IMAGES +
                                transporterDetails.user_grade_certificate_document
                              : document_image
                          }
                          alt=""
                          style={{ width: "100%" }}
                        />
                        <div className="option-dropdown dropdown">
                          <a
                            href=""
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img
                              src={ic_options_menu}
                              className="dot-icon"
                              alt=""
                            />
                          </a>
                          <ul className="dropdown-menu ">
                            <li>
                              <button
                                onClick={() => {
                                  handleDownload(
                                    ApiConfig.BASE_URL_FOR_IMAGES +
                                      transporterDetails.user_grade_certificate_document
                                  );
                                }}
                                className="dropdown-item"
                                type="button"
                              >
                                Download
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mt-2">
                      <label htmlFor="">TIN</label>
                      <div className="d-flex mx-1">
                        <img
                          src={
                            transporterDetails.user_tn_document
                              ? ApiConfig.BASE_URL_FOR_IMAGES +
                                transporterDetails.user_tn_document
                              : document_image
                          }
                          alt=""
                          style={{ width: "100%" }}
                        />
                        <div className="option-dropdown dropdown ">
                          <a
                            href=""
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img
                              src={ic_options_menu}
                              className="dot-icon"
                              alt=""
                            />
                          </a>
                          <ul className="dropdown-menu ">
                            <li>
                              <button
                                onClick={() => {
                                  handleDownload(
                                    ApiConfig.BASE_URL_FOR_IMAGES +
                                      transporterDetails.user_tn_document
                                  );
                                }}
                                className="dropdown-item"
                                type="button"
                              >
                                Download
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </PDFExport>
              </div>
            </div>
          </div>
        </div>
      )}
    </main>
  );
};

export default TransporterDetails;
