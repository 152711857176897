import React, { useContext, useEffect, useState } from "react";
import dropdown_arrow_blue from "../../assets/images/dropdown_arrow_blue.svg";
import ic_options from "../../assets/images/Trasnvehicle/ic_options.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { AppContext } from "../../context/user/AppContext";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../../sharedComponent/Loader";
import swal from "sweetalert";
const TransporterResponse = () => {
  const [all, setAll] = useState(false);
  const navigate = useNavigate();
  const request = JSON.parse(localStorage.getItem("request"));
  const [requestID] = useState(request && request.trip_id);
  const [respondedTransporterList, setRespondedTransporterList] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);


  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const { mainClass, customerData, setApiCalling } = useContext(AppContext);
  $(document).ready(function () {
    let table;
    if ($.fn.dataTable.isDataTable(".dataList")) {
      table = $(".dataList").DataTable();
    } else {
      table = $(".dataList").DataTable({
        ordering: false,

        language: {
          search: "",
          searchPlaceholder: "Search...",
          sLengthMenu: "Entries per page _MENU_",
        },
      });
    }
  });

  useEffect(() => {
    setLoading(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.DIRECT_ORDER_VIEW_RESPONSE,
      JSON.stringify({ ...customerData, load_id: requestID })
    )
      .then((res) => {
        setLoading(false);
        if (res.json.result) {
          setData(res.json);
          setRespondedTransporterList(
            res.json.transporters.map((trans) => {
              return {
                ...trans,
                transporter_id: trans.transporter_id,
                checked: false,
              };
            })
          );
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
    return () => {};
  }, []);

  const sedOffer = () => {
    setApiCalling(true);

    postWithAuthCallWithErrorResponse(
      ApiConfig.DIRECT_ORDER_SEND_OFFER,
      JSON.stringify({
        ...customerData,
        load_id: requestID,
        transporter_ids: selectedIds,
      })
    )
      .then((res) => {
        setApiCalling(false);
        swal(res.json.message);
        if (res.json.result) navigate("/DirectOrderHeader");
      })
      .catch((res) => {
        setApiCalling(false);
      });
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const allIds = respondedTransporterList.map(
        (checkbox) => checkbox.transporter_id
      );
      setSelectedIds(allIds);
    } else {
      setSelectedIds([]);
    }
  };

  const handleCheckboxChange = (event) => {
    const checkboxId = Number(event.target.id);
    if (event.target.checked) {
      setSelectedIds((prevIds) => [...prevIds, checkboxId]);
    } else {
      setSelectedIds((prevIds) => prevIds.filter((id) => id !== checkboxId));
    }
  };
  return (
    <main
      className={`vehicle Transporters-Response-main ${mainClass}`}
      id="cxMain"
    >
      <div className="">
        <div style={{ width: "100%", overflow: "auto", padding: "30px 0" }}>
          {loading ? (
            <Loader loading={loading} />
          ) : (
            <table
              className="table-striped dataList"
              style={{ maxWidth: "800px" }}
            >
              <thead>
                <tr>
                  <th>
                    <div className="form-check pt-2 ms-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="select-all"
                        onChange={handleSelectAll}
                      />
                    </div>
                  </th>
                  <th>S.No</th>
                  <th>Transporter’s Name</th>
                  <th>Responded On</th>
                  <th>Unit Price</th>
                  <th>Loading Price</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {respondedTransporterList.map((transporter, index) => {
                  return (
                    <tr className="Rowbgcolor " key={"transporter" + index}>
                      <div className="form-check pt-2 ms-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={transporter.transporter_id}
                          onChange={handleCheckboxChange}
                          checked={selectedIds.includes(
                            transporter.transporter_id
                          )}
                        />
                      </div>
                      <td>{index + 1}</td>
                      <td>{transporter.transporter}</td>
                      <td>
                        {transporter.responded_on} {transporter.responded_time}
                      </td>
                      <td>{data.unit_price}</td>
                      <td>{data.loading_price}</td>
                      <td>{transporter.vehicle_request_status}</td>
                      <td>
                        <div className="option-dropdown dropdown p-2">
                          <a
                            href=""
                            className="ms-5 ps-5"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img className="" src={ic_options} alt="" />
                          </a>
                          <ul className="dropdown-menu ">
                            <li>
                              <button className="dropdown-item" type="button">
                                Edit{" "}
                                <img
                                  src={dropdown_arrow_blue}
                                  className="arrowvehicle"
                                  alt=""
                                />
                              </button>
                            </li>
                            <li>
                              <Link
                                to={"/ViewDetails/" + requestID}
                                className="text-decoration-none"
                                state={transporter}
                              >
                                <button
                                  className="dropdown-item "
                                  type="button"
                                >
                                  View Details
                                </button>
                              </Link>
                            </li>
                            <li></li>

                            <li>
                              <button className="dropdown-item" type="button">
                                Change Over
                              </button>
                            </li>
                            <li>
                              <button className="dropdown-item" type="button">
                                Block
                              </button>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <div className="fix-bottom-bar d-flex justify-content-between align-items-center mt-4">
        <div className="left"></div>
        <div className="right">
          <a href="">
            <button type="submit" className="cancel-btn">
              Cancel
            </button>
          </a>
          <Link to="#">
            <button
              type="submit"
              className="continue-btn"
              id="continue-btn"
              onClick={sedOffer}
            >
              Send Offer
            </button>
          </Link>
        </div>
      </div>
    </main>
  );
};

export default TransporterResponse;
