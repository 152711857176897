import React, { useContext, useEffect, useState } from "react";
import ic_trucks from "../../assets/images/Skyscrapers.jpg";
import ic_options from "../../assets/images/megaoffer/ic_options.svg";
import { Link, useNavigate } from "react-router-dom";
import "../../assets/css/style.scss";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { AppContext } from "../../context/user/AppContext";
import Loader from "../../sharedComponent/Loader";
import ic_dropdown_arrow from "../../assets/images/dropdown_arrow_blue.svg";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
const OfferVehicles = () => {
  const { customerData, mainClassB } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [orderShipper, setOrderShipper] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    getAllRequests();
  }, []);
  const getAllRequests = () => {
    setLoading(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.DRIRECT_TRANSPORTER_STATUS,
      JSON.stringify({ ...customerData })
    )
      .then((res) => {
        setLoading(false);
        if (res.json.result) {
          setOrderShipper(res.json.load_list);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  $(document).ready(function () {
    let table;
    if ($.fn.dataTable.isDataTable(".dataList")) {
      table = $(".dataList").DataTable();
    } else {
      table = $(".dataList").DataTable({
        ordering: false,

        language: {
          search: "",
          searchPlaceholder: "Search...",
          sLengthMenu: "Entries per page _MENU_",
        },
      });
    }
    // $('#transporterList').DataTable({
    //   // ordering: false
    // });
  });

  return (
    <React.Fragment>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <main className={`direct-order-main ${mainClassB}`} id="cxMainB">
          <div style={{ margin: "20px" }}>
            <div className=" all-request">
              <div className="  ">
                <div className="offerLoadsMainLists">
                  <div className="mainLoadTable">
                    <table className="dataList" style={{ minWidth: "1170px" }}>
                      <thead>
                        <tr>
                          <th>
                            <label>S.No</label>
                          </th>
                          <th>
                            <label>Reference No.</label>
                          </th>
                          <th>
                            <label>From-To</label>
                          </th>
                          <th>
                            <label>Offered By</label>
                          </th>
                          <th>
                            <label>Cargo Type</label>
                          </th>
                          <th>
                            <label>Quantity</label>
                          </th>
                          <th>
                            <label>Est.Start.Dt</label>
                          </th>
                          <th>
                            <label>Status</label>
                          </th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderShipper.length > 0 ? (
                          orderShipper.map((request, index) => {
                            return (
                              <tr className="Rowbgcolor">
                                <td>
                                  <label>{index + 1}</label>
                                </td>
                                <td>
                                  <label>
                                    <img
                                      src={
                                        ApiConfig.BASE_URL_FOR_IMAGES +
                                          request.trip_image || ic_trucks
                                      }
                                      alt=""
                                      className="directLoad-img card-rsponsive-img vehicle-request-img"
                                      style={{
                                        height: "22px",
                                        width: "22px",
                                        marginRight: "7px",
                                      }}
                                    />
                                    <Link
                                      className="green-link-btn"
                                      to={"/ViewDetails/" + request.trip_id}
                                    >
                                      {request.load_reference_no}
                                    </Link>
                                  </label>
                                </td>
                                <td>
                                  <label>
                                    {request.trip_start_city}{" "}
                                    {request.trip_start_country} -{" "}
                                    {request.trip_end_city}{" "}
                                    {request.trip_end_country}
                                  </label>
                                </td>
                                <td>
                                  <label>{request.offered_by}</label>
                                </td>
                                <td>
                                  <label>{request.cargo_type}</label>
                                </td>
                                <td>
                                  <label>
                                    {request.pending_quantity
                                      ? request.pending_quantity
                                      : request.quantity}{" "}
                                    {request.unit}.
                                  </label>
                                </td>
                                <td>
                                  <label>{request.estimated_start_date}</label>
                                </td>
                                <td>
                                  <label>
                                    {request.trip_status ===
                                      "Transporters Assigned" && (
                                      <>
                                        <Link to="#">
                                          <button
                                            type="button"
                                            className="  text-center TransportersAssigned-btn"
                                          >
                                            Transporter's Assigned
                                          </button>
                                        </Link>
                                        <br />
                                      </>
                                    )}
                                    {request.trip_status ===
                                      "Order Sent to Shipper" && (
                                      <>
                                        <Link to="#">
                                          <button
                                            type="button"
                                            className="  text-center TransportersAssigned-btn"
                                          >
                                            {request.trip_status}
                                          </button>
                                        </Link>{" "}
                                        <br />
                                      </>
                                    )}
                                    {request.trip_status ===
                                      "shipper_accepted" && (
                                      <>
                                        <Link to="#">
                                          <button
                                            type="button"
                                            className="  text-center TransportersAssigned-btn"
                                          >
                                            Shipper has approved the offer
                                          </button>
                                        </Link>
                                        <br />

                                        <br />
                                        <Link
                                          to="#"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            localStorage.setItem(
                                              "request",
                                              JSON.stringify(request)
                                            );
                                            navigate("/MatchVechicleWithCargo");
                                          }}
                                        >
                                          <button
                                            type="button"
                                            className="green-link-btn "
                                          >
                                            Assign Vehicles to Cargo
                                          </button>
                                        </Link>
                                      </>
                                    )}
                                    {request.trip_status ===
                                      "shipper_rejected" && (
                                      <>
                                        <Link to="#">
                                          <button
                                            type="button"
                                            className="  text-center TransportersAssigned-btn"
                                          >
                                            Shipper has Rejected the Offer
                                          </button>
                                        </Link>{" "}
                                        <br />
                                        <Link
                                          to="#"
                                          nClick={(e) => {
                                            e.preventDefault();
                                            localStorage.setItem(
                                              "request",
                                              JSON.stringify(request)
                                            );
                                            navigate(
                                              "/AddVehicleSpecification"
                                            );
                                          }}
                                        >
                                          <button
                                            type="button"
                                            className="green-link-btn "
                                          >
                                            Reassign Transporter
                                          </button>
                                        </Link>
                                      </>
                                    )}
                                    {request.trip_status === "match_made" && (
                                      <>
                                        <Link to="#">
                                          <button
                                            type="button"
                                            className="  text-center TransportersAssigned-btn"
                                          >
                                            Matching Making has been completed
                                          </button>
                                        </Link>{" "}
                                        <br />
                                        <Link
                                          to="#"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            localStorage.setItem(
                                              "request",
                                              JSON.stringify(request)
                                            );
                                            navigate(
                                              "/OrderConfirmationForTransporter"
                                            );
                                          }}
                                        >
                                          <button
                                            type="button"
                                            className="green-link-btn "
                                          >
                                            Send Order Confirmation
                                          </button>
                                        </Link>
                                      </>
                                    )}
                                    {request.trip_status ===
                                      "Transporters Responded" && (
                                      <>
                                        <Link to="#">
                                          <button
                                            type="button"
                                            className="  text-center TransportersAssigned-btn"
                                          >
                                            Transporter’s Have Responded
                                          </button>
                                        </Link>{" "}
                                        <br />
                                        <Link
                                          to="#"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            localStorage.setItem(
                                              "request",
                                              JSON.stringify(request)
                                            );
                                            navigate("/TransporterResponse");
                                          }}
                                        >
                                          <button
                                            type="button"
                                            className="green-link-btn "
                                          >
                                            View Response
                                          </button>
                                        </Link>
                                      </>
                                    )}
                                    {request.trip_status === "assigned" && (
                                      <>
                                        <Link to="#">
                                          <button
                                            type="button"
                                            className="  text-center TransportersAssigned-btn"
                                          >
                                            {request.trip_status}
                                          </button>
                                        </Link>{" "}
                                        <br />
                                      </>
                                    )}
                                  </label>
                                </td>
                                <td>
                                  <label>
                                    <div className="option-dropdown dropdown ">
                                      <Link
                                        to="#"
                                        href=""
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <img src={ic_options} alt="" />
                                      </Link>
                                      <ul className="dropdown-menu ">
                                        {/* <li>
                                          <Link
                                            to={
                                              "/ViewDetails/" + request.trip_id
                                            }
                                            state={request}
                                            className="text-decoration-none"
                                          >
                                            <button
                                              className="dropdown-item"
                                              type="button"
                                            >
                                              View Details
                                              <img
                                                src={ic_dropdown_arrow}
                                                className="arrowvehicle"
                                                alt=""
                                              />
                                            </button>
                                          </Link>
                                        </li> */}
                                        <li>
                                          <button
                                            className="dropdown-item mt-2"
                                            type="button"
                                            to="#offcanvasDriverAccount"
                                            data-bs-toggle="offcanvas"
                                            data-bs-target="#offcanvasFrightStatus"
                                            aria-controls="offcanvasRightAccount"
                                          >
                                            View Transporter Response
                                          </button>
                                        </li>
                                      </ul>
                                    </div>
                                  </label>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <div
                            className="text-danger"
                            style={{
                              alignItems: "center",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {" "}
                            No Load Available
                          </div>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      )}
    </React.Fragment>
  );
};

export default OfferVehicles;
