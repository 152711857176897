import React, { useContext, useState } from "react";
import { AppContext } from "../../context/user/AppContext";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useEffect } from "react";
import Select from "react-select";

import {
  PostCallWithErrorResponse,
  postMultipartWithAuthCallWithErrorResponse,
  postWithAuthCallWithErrorResponse,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import swal from "sweetalert";
const TransporterRates = () => {
  const { mainClassB, setApiCalling, customerData } = useContext(AppContext);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [selectedLIstId, setSelectedLIstId] = useState("");
  const [currencyList, setCurrenctList] = useState([]);

  const [rateChartList, setRateChartList] = useState([]);
  const [state, setState] = useState({
    category: "",
    rate_chart_id: "",
    currency: "",
    rate_20ft: "",
    rate_40ft: "",
    break_bulk: "",
    bulk: "",
    other: "",
    roro: "",
  });
  const customStyles = {
    input: (provided, state) => ({
      ...provided,
      gridTemplateColumns: "0px 100%",
    }),
  };
  const [errMsg, setErrMsg] = useState({
    category: "",
    currency: "",
    rate_20ft: "",
    rate_40ft: "",
    break_bulk: "",
    bulk: "",
    other: "",
    roro: "",
  });
  useEffect(() => {
    getCurrencyDropDown();
    RateChartList();
  }, [selectedLIstId]);
  const EditTarrifExport = () => {
    setApiCalling(true);

    if (state.category === "") {
      setErrMsg({ ...errMsg, category: "category is required" });
      // window.scrollTo(0, 0);
      return;
    }
    if (state.currency === "") {
      setErrMsg({ ...errMsg, currency: "currency is required" });
      // window.scrollTo(0, 0);
      return;
    }
    if (state.rate_20ft === "") {
      setErrMsg({ ...errMsg, rate_20ft: "Rate is required" });
      // window.scrollTo(0, 0);
      return;
    }
    if (state.rate_40ft === "") {
      setErrMsg({ ...errMsg, rate_40ft: "Rate is required" });
      // window.scrollTo(0, 0);
      return;
    }
    if (state.break_bulk === "") {
      setErrMsg({ ...errMsg, break_bulk: "Break Bulk is required" });
      // window.scrollTo(0, 0);
      return;
    }
    if (state.bulk === "") {
      setErrMsg({ ...errMsg, bulk: "Bulk is required" });
      // window.scrollTo(0, 0);
      return;
    }
    if (state.roro === "") {
      setErrMsg({ ...errMsg, roro: "RORO Unit is required" });
      // window.scrollTo(0, 0);
      return;
    }
    if (state.other === "") {
      setErrMsg({ ...errMsg, roro: "Other is required" });
      // window.scrollTo(0, 0);
      return;
    } else {
      
      setState({
        customer_id: customerData.customer_id,
        user_id: customerData.user_id,
        api_key: customerData.api_key,
        rate_chart_id: selectedLIstId.rate_chart_id,
        category: state.category,
        currency: state.currency,
        rate_20ft: state.rate_20ft,
        rate_40ft: state.rate_40ft,
        break_bulk: state.break_bulk,
        bulk: state.bulk,
        other: state.other,
        roro: state.roro,
      });
      postMultipartWithAuthCallWithErrorResponse(
        ApiConfig.RATE_CHART_EDIT,
        JSON.stringify({ ...customerData, ...state })
      )
        .then((res) => {
          handleClose("");
          RateChartList();
          if (res.json.result) {
            swal({
              title: "success",
              text: res.json.message,
              icon: "success",
              button: true,
            });
          } else if (res.json.result === false)
            swal({
              title: "warning",
              text: res.json.message,
              icon: "warning",
              button: true,
            });

          setApiCalling(false);
        })
        .catch((err) => console.log(err));
    }
  };
  const RateChartList = () => {
    setApiCalling(true);
    setState({
      category: selectedLIstId.rate_chart_category,
      currency: selectedLIstId.currency,
      rate_chart_id: JSON.stringify(selectedLIstId.rate_chart_id),
    });
    postWithAuthCallWithErrorResponse(
      ApiConfig.RATE_CHART_LIST,
      JSON.stringify({ ...customerData })
    ).then((res) => {
      setRateChartList(res.json.rate_chart_list);
      setApiCalling(false);
    });
  };
  const getCurrencyDropDown = () => {
    setApiCalling(true);
    PostCallWithErrorResponse(ApiConfig.CURRENCY_DROPDOWN)
      .then((res) => {
        setApiCalling(false);
        setCurrenctList(
          res.json.currency_list.map((currency) => {
            return {
              value: currency.currency_id,
              label: currency.currency_name,
            };
          })
        );
      })
      .catch((err) => {
        setApiCalling(false);
        console.log(err);
      });
  };
  const handleClick = () => {
    handleShow();
  };
  return (
    <main className={`contract-main-section ${mainClassB}`} id="cxMainB">
      <div className="rates-main " style={{ margin: "0 20px" }}>
        <div className="transporter">
          {/* <div className="heading-top">
            <input
              type="text"
              className="form-control mb-3"
              placeholder="Search"
            />
          </div> */}
          <div className="offerLoadsMainLists">
            <div className="mainLoadTable" style={{ overflow: "auto" }}>
              <table className="dataList" style={{ minWidth: "900px" }}>
                <thead>
                  <tr>
                    <th>
                      <label>S.No.</label>
                    </th>
                    <th>
                      <label>Rate type /Category</label>
                    </th>
                    <th>
                      <label>Currency</label>
                    </th>
                    <th>
                      <label>20 ft(teu)</label>
                    </th>

                    <th>
                      <label>40 ft(feu)</label>
                    </th>
                    <th className="">
                      <label>Break bulk(freight tone)</label>
                    </th>
                    <th>
                      <label>Bulk (tone)</label>
                    </th>
                    <th>
                      <label>RORO(Unit)</label>
                    </th>
                    <th>
                      <label>Other</label>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {rateChartList.map((rateListItem, index) => {
                    return (
                      <tr className="Rowbgcolor" key={"rateListItem" + index}>
                        <td>
                          <label>{index + 1}</label>
                        </td>
                        <td>
                          <label>{rateListItem.rate_chart_category}</label>
                        </td>
                        <td>
                          <label>{rateListItem.currency_code}</label>
                        </td>
                        <td>
                          <label>{rateListItem.rate_chart_20ft}</label>
                        </td>

                        <td>
                          <label>{rateListItem.rate_chart_40ft}</label>
                        </td>

                        <td>
                          <label>{rateListItem.rate_chart_break_bulk}</label>
                        </td>
                        <td>
                          <label>{rateListItem.rate_chart_bulk}</label>
                        </td>
                        <td>
                          <label>{rateListItem.rate_chart_roro}</label>
                        </td>
                        <td>
                          <label>{rateListItem.rate_chart_other}</label>
                        </td>
                        <td>
                          <label>
                            <button
                              className="editBTN-rateTable"
                              onClick={() =>
                                handleClick(setSelectedLIstId(rateListItem))
                              }
                            >
                              Edit
                            </button>
                          </label>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <Modal
            show={show}
            onHide={handleClose}
            centered
            className="modal-edit-rate"
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit Shipper Rate (Import)</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div id="main-rate-edit">
                <div className="row">
                  <div class="mb-3 col-md-6">
                    <label for="exampleInputEmail1" class="form-label">
                      Rate type /Category
                    </label>
                    <input
                      type="email"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      disabled
                      value={state.category}
                      onChange={(e) => {
                        setErrMsg({ ...errMsg, category: "" });
                        setState({ ...state, category: e.target.value });
                      }}
                    />
                    {errMsg.category.length > 0 && (
                      <div className="text-center text-danger">
                        <span> {errMsg.category}</span>
                      </div>
                    )}
                  </div>
                  <div class="mb-3 col-md-6">
                    <label for="exampleInputEmail1" class="form-label">
                      Currency
                    </label>
                    <Select
                      options={currencyList}
                      onChange={(e) => {
                        setErrMsg({ ...errMsg, currency: "" });
                        setState({ ...state, currency: e.value });
                      }}
                      styles={customStyles}
                      value={currencyList.filter(
                        (option) => option.value === state.currency
                      )}
                    />
                    {errMsg.currency.length > 0 && (
                      <div className="text-center text-danger">
                        <span> {errMsg.currency}</span>
                      </div>
                    )}
                  </div>
                  <div class="mb-3 col-md-6">
                    <label for="exampleInputEmail1" class="form-label">
                      20 ft(teu)
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        setErrMsg({ ...errMsg, rate_20ft: "" });
                        setState({ ...state, rate_20ft: e.target.value });
                      }}
                    />
                    {errMsg.rate_20ft.length > 0 && (
                      <div className="text-center text-danger">
                        <span> {errMsg.rate_20ft}</span>
                      </div>
                    )}
                  </div>
                  <div class="mb-3 col-md-6">
                    <label for="exampleInputEmail1" class="form-label">
                      40 ft(feu)
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        setErrMsg({ ...errMsg, rate_40ft: "" });
                        setState({ ...state, rate_40ft: e.target.value });
                      }}
                    />
                    {errMsg.rate_40ft.length > 0 && (
                      <div className="text-center text-danger">
                        <span> {errMsg.rate_40ft}</span>
                      </div>
                    )}
                  </div>
                  <div class="mb-3 col-md-6">
                    <label for="exampleInputEmail1" class="form-label">
                      Break bulk(freight tone)
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        setErrMsg({ ...errMsg, break_bulk: "" });
                        setState({ ...state, break_bulk: e.target.value });
                      }}
                    />
                    {errMsg.break_bulk.length > 0 && (
                      <div className="text-center text-danger">
                        <span> {errMsg.break_bulk}</span>
                      </div>
                    )}
                  </div>
                  <div class="mb-3 col-md-6">
                    <label for="exampleInputEmail1" class="form-label">
                      Bulk (tone)
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        setErrMsg({ ...errMsg, bulk: "" });
                        setState({ ...state, bulk: e.target.value });
                      }}
                    />
                    {errMsg.bulk.length > 0 && (
                      <div className="text-center text-danger">
                        <span> {errMsg.bulk}</span>
                      </div>
                    )}
                  </div>
                  <div class="mb-3 col-md-6">
                    <label for="exampleInputEmail1" class="form-label">
                      RORO(Unit)
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        setErrMsg({ ...errMsg, roro: "" });
                        setState({ ...state, roro: e.target.value });
                      }}
                    />
                    {errMsg.roro.length > 0 && (
                      <div className="text-center text-danger">
                        <span> {errMsg.roro}</span>
                      </div>
                    )}
                  </div>
                  <div class="mb-3 col-md-6">
                    <label for="exampleInputEmail1" class="form-label">
                      Other
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        setErrMsg({ ...errMsg, other: "" });
                        setState({ ...state, other: e.target.value });
                      }}
                    />
                    {errMsg.other.length > 0 && (
                      <div className="text-center text-danger">
                        <span> {errMsg.other}</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleClose} id="closebtnrate">
                Close
              </Button>
              <Button onClick={EditTarrifExport} id="savebtnrate">
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </main>
  );
};

export default TransporterRates;
