import React, { useContext } from "react";
import Skyscrapers from "../../assets/images/Skyscrapers.jpg";
import ic_options_menu from "../../assets/images/ic_options_menu.svg";
import star_highlighted from "../../assets/images/vehiclesDetails/star_highlighted.svg";
import star_grey from "../../assets/images/vehiclesDetails/ic_star_grey.svg";
import dropdown_arrow_blue from "../../assets/images/dropdown_arrow_blue.svg";
import { Link } from "react-router-dom";
import ChangeDriver from "./ChangeDriver";
import { AppContext } from "../../context/user/AppContext";

const AuctionDetails = () => {
  const { mainClass } = useContext(AppContext);
  return (
    <>
      {/* <Sidebar/>
         <AbhayHeader/> */}
      <main className={`main-section-auctionDetails ${mainClass}`} id="cxMain">
        {/* Auction rating */}
        <section className="common-body mt-4">
          <div className="top d-flex justify-content-between">
            <p className="data-name">Kevin Trapp</p>
            <p className="amount">$420</p>
          </div>
          <div className="rating">
            <img src={star_highlighted} alt="" />
            <img src={star_highlighted} alt="" />

            <img src={star_highlighted} alt="" />
            <img src={star_highlighted} alt="" />
            <img src={star_grey} alt="" />
            <span className="ms-2">(320)</span>
          </div>
          <div className="date">
            <label htmlFor="">27 Dec 2021</label>
          </div>
        </section>
        {/* Vehicle details */}
        <section className="vehicle-detail-section  common-body p-3 mt-4">
          <div className="row">
            <div className="col-lg-6">
              <div className="image">
                <div
                  id="carouselExampleControls"
                  className="carousel slide"
                  data-bs-ride="carousel"
                >
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img src={Skyscrapers} className="d-block " alt={"..."} />
                    </div>
                    <div className="carousel-item">
                      <img src={Skyscrapers} className="d-block " alt="..." />
                    </div>
                    <div className="carousel-item">
                      <img src={Skyscrapers} className="d-block " alt="..." />
                    </div>
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleControls"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleControls"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="right-section">
                <div className="right-top-content">
                  <div className="icon-dropdown dropdown">
                    <a
                      href=""
                      className=""
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img src={ic_options_menu} alt="" />
                    </a>

                    <ul className="dropdown-menu">
                      <li>
                        <button className="dropdown-item" type="button">
                          Edit{" "}
                          <img
                            src={dropdown_arrow_blue}
                            className="arrow"
                            alt=""
                          />
                        </button>
                      </li>
                      <li>
                        <Link
                          to="/ChangeDriver"
                          className="text-decoration-none"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasDriverAccount"
                          aria-controls="offcanvasRightAccount"
                        >
                          <button className="dropdown-item " type="button">
                            Change Driver
                          </button>
                        </Link>

                      </li>
                      <li>
                        <button className="dropdown-item" type="button">
                          Block
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="title">
                    <h4 className="name">Eicher Pro 1110XP</h4>
                  </div>
                  {/* <div className="rating">
                      <img src={star_highlighted} alt="" />
                      <img src={star_highlighted} alt="" />

                      <img src={star_highlighted} alt="" />
                      <img src={star_highlighted} alt="" />
                      <img src={star_grey} alt="" />
                      <span>(320)</span>
                    </div> */}
                </div>
                <div className="bottom-content row mt-4">
                  <div className="col-lg-4 left item">
                    <label>Plate Number</label>
                    <p className="data">UI343546UF890</p>
                  </div>
                  <div className="col-lg-4 middel item">
                    <label>Vehicle Type</label>
                    <p className="data">Truck - Trailer</p>
                  </div>
                  <div className=" col-lg-4 right item">
                    <label>Year of Manufacture</label>
                    <p className="data">Reefer</p>
                  </div>
                </div>
                <div className="bottom-content row mt-3">
                  <div className="col-lg-4 left item">
                    <label>Chassis Number</label>
                    <p className="data">45678</p>
                  </div>
                  <div className="col-lg-4 middel item">
                    <label>Motor Number</label>
                    <p className="data">2345</p>
                  </div>
                  <div className="col-lg-4 right item">
                    <label>Gross Weight</label>
                    <p className="data">2345</p>
                  </div>
                </div>
                <div className="bottom-content row mt-3">
                  <div className="col-lg-4 left item">
                    <label>Vehicle Load Capacity</label>
                    <p className="data">10 Quintals</p>
                  </div>

                  <div className="col-lg-4 right item">
                    <label>Initial km</label>
                    <p className="data">1234</p>
                  </div>
                </div>
                <h5 className="card-heading">GPS Availability</h5>
                <div className="bottom-content row mt-3">
                  <div className="col-lg-4 left item">
                    <label>Vendor Name</label>
                    <p className="data">Name</p>
                  </div>

                  <div className="col-lg-4 right item">
                    <label>Vendor Contact</label>
                    <p className="data">Contact</p>
                  </div>
                  <div className="col-lg-4 right item">
                    <label>Vendor Platform</label>
                    <p className="data">Platform</p>
                  </div>
                  <div className="col-lg-4 right item">
                    <label>Vendor Address</label>
                    <p className="data">Address</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="fix-bottom-bar d-flex justify-content-between align-items-center mt-4">
          <div className="left"></div>
          <div className="right">
            <a href="" >

              <button type="concel" className="cancel-btn">
                Reject
              </button>
            </a>
            <Link to="/VehiclesDetailsOffer" >

              <button type="submit" className="continue-btn">
                Accept
              </button>
            </Link>
            <Link to="/VehiclesDetailsOffer" className="continue-btn">
              !

            </Link>
          </div>
        </div>
      </main>
      <ChangeDriver />
    </>
  );
};

export default AuctionDetails;
