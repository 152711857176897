import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/user/AppContext";

const CreateAuction = () => {
  let previouspageData = JSON.parse(
    localStorage.getItem("currentVehicleRequest")
  );
  const { customerData, orderType, auctionDetails, setAuctionDetails } =
    useContext(AppContext);
  let [loadDetails, setLoadDetails] = useState({ ...previouspageData });


  const navigate = useNavigate();
  return (
    <div className="vehicle-list cx-active-sidebar" id="cxMain">
      <div className="create-auction-section ">
        <div className="card card-style  p-4">
          <h5 className="card-heading">Create Auction</h5>
          <hr />
          <div className="form-group mt-2">
            <label htmlFor="exampleInputEmail1">Auction Name </label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              onChange={(e) =>
                setAuctionDetails({
                  ...auctionDetails,
                  auction_name: e.target.value,
                })
              }
              value={auctionDetails.auction_name}
            />
          </div>
          <div className="form-group mt-4">
            <label htmlFor="exampleInputEmail1">Auction Type</label>
            <select
              className="form-select"
              aria-label="Default select example"
              onChange={(e) =>
                setAuctionDetails({
                  ...auctionDetails,
                  auction_type: e.target.value,
                })
              }
              defaultValue={auctionDetails.auction_type}
            >
              <option selected>Select Auction Type</option>
              <option value="Short Term">Short Term</option>
              {/* <option value="2">Two</option>
              <option value="3">Three</option> */}
            </select>
          </div>
          <div className="form-group mt-4">
            <label htmlFor="exampleInputEmail1">StartDate </label>
            <input
              type="date"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              onChange={(e) =>
                setAuctionDetails({
                  ...auctionDetails,
                  start_date: e.target.value,
                })
              }
              value={auctionDetails.start_date}
            />
          </div>
          <div className="form-group mt-4">
            <label htmlFor="exampleInputEmail1">Expiry Date </label>
            <input
              type="date"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              onChange={(e) =>
                setAuctionDetails({
                  ...auctionDetails,
                  expiry_date: e.target.value,
                })
              }
              value={auctionDetails.expiry_date}
            />
          </div>
          {/* <Link to="/OnlineAuctionHeader"> */}
          <button
            type="submit"
            data-bs-dismiss="offcanvas"
            className=" auction-btn  mt-5"
            onClick={() => {
              navigate("/SelectTransporter");
            }}
          >
            Create
          </button>
          {/* </Link> */}
        </div>
      </div>
    </div>
  );
};

export default CreateAuction;
