import React, { useContext, useEffect, useState } from "react";
import right_arrow from "../../assets/images/ic_right_arrow.svg";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "../../context/user/AppContext";
import { Modal } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import swal from "sweetalert";
import Loader from "../../sharedComponent/Loader";

const Auctions = () => {
  const [show, setShow] = useState(false);
  const [vehicleModel, setvehicleModel] = useState(false);
  const navigate = useNavigate();
  const previousPageData = JSON.parse(localStorage.getItem("request"));
  const { mainClass, customerData, setApiCalling, ApiCaaling } =
    useContext(AppContext);
  const [auctionDetails, setAuctionDetails] = useState({
    ...previousPageData,
    bid_list: [],
    vehicle_Details: [],
  });
  const [selectedBid, seSelectedBid] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.ONLINE_ORDERES_DETAILS,
      JSON.stringify({ ...customerData, load_id: auctionDetails.trip_id })
    )
      .then((res) => {
        setLoading(false);
        if (res.json.result) {
          setAuctionDetails({
            ...auctionDetails,
            ...res.json.auction_details,
            bid_list: res.json.bid_list,
            vehicle_Details: res.json.bid_list[0].vehicle_list,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };
  const sendOffer = () => {
    setApiCalling(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.ONLINE_ORDERS_SEND_OFFER,
      JSON.stringify({ ...customerData, bid_id: selectedBid })
    )
      .then((res) => {
        setApiCalling(false);
        if (res.json.result) {
          swal(res.json.message);
          navigate("/OnlineAuctionHeader");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <main className="Auctions-main">
      <div className={`main ${mainClass}`} id="cxMain">
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <div className="details-main ">
            <div className="Auctioncards ">
              <div className="details-card ">
                <div className="row justify-content-center p-2">
                  {/* left card */}
                  <div className="col-lg-5  mt-2">
                    <div className="card card-style left-card ">
                      <div className="m-2">
                        <div className="row">
                          <div className=" ps-4  card_title d-flex justify-content-between align-items-start ">
                            <h4 className="card-gods ">Auction Details</h4>
                          </div>

                          <div className="user_block ">
                            <div
                              className=" justify-content-between align-items-start  "
                              id="short"
                            >
                              <div className="  d-flex align-items-middle justify-content-between  p-1 rsponsive-card-data">
                                <h5 className="godside">Auction Name </h5>
                                <span className="allside">
                                  {auctionDetails.auction_name}
                                </span>
                              </div>
                            </div>
                            <div
                              className=" justify-content-between align-items-start  "
                              id="days"
                            >
                              <div className="  d-flex align-items-middle justify-content-between  p-1 rsponsive-card-data">
                                <h5 className="godside">Auction Duration</h5>
                                <span className="allside">
                                  {" "}
                                  {auctionDetails.duration} days
                                </span>
                              </div>
                            </div>

                            <div
                              className=" justify-content-between align-items-start  "
                              id="short"
                            >
                              <div className="p-1  d-flex align-items-middle justify-content-between  rsponsive-card-data">
                                <h5 className="godside">Auction Type</h5>
                                <span className="allside">
                                  {auctionDetails.auction_type}
                                </span>
                              </div>
                            </div>

                            <div
                              className=" justify-content-between align-items-start  "
                              id="days"
                            >
                              <div className="  d-flex align-items-middle justify-content-between  p-1 rsponsive-card-data">
                                <h5 className="godside"> Start Date</h5>
                                <span className="allside">
                                  {auctionDetails.auction_start_date}
                                </span>
                              </div>
                            </div>
                            <div
                              className=" justify-content-between align-items-start  "
                              id="short"
                            >
                              <div className="p-1 d-flex align-items-middle justify-content-between  rsponsive-card-data">
                                <h5 className="godside">Expiry Date</h5>
                                <span className="allside">
                                  {auctionDetails.auction_end_date}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="row  make_bid m-1">
                              <button
                                type="button"
                                data-bs-dismiss="offcanvas"
                                className="btn-lg     d-flex
                            justify-content-between
                            align-items-center"
                                id="save"
                              >
                                <span>
                                  <Link
                                    to="/GoodsDetails"
                                    className="text-decoration-none"
                                    state={auctionDetails}
                                  >
                                    View Goods Details{" "}
                                  </Link>
                                </span>
                                <img src={right_arrow} alt="" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* RIGHT CARD  */}
                  <div className="col-lg-5  mt-2">
                    <div className="card card-style left-card ">
                      <div className="container-fluid ">
                        <div className="row pt-2">
                          <div className=" d-flex justify-content-between ">
                            <h4 className="card-gods ">All Bids</h4>
                          </div>

                          {auctionDetails.bid_list.map((bid) => {
                            return (
                              <div
                                className=" justify-content-between align-items-start mb-2 "
                                id="short"
                              >
                                <div className="all-bids-items p-2">
                                  <div className="  d-flex justify-content-between  rsponsive-card-data">
                                    <span className="allside">
                                      {bid.user_name} - {bid.date_time}
                                    </span>
                                    <span className="allside">${bid.rate}</span>
                                  </div>
                                  <Link
                                    to="#"
                                    onClick={() => setvehicleModel(true)}
                                  >
                                    View Vehicle Details
                                  </Link>
                                  <hr />
                                  <div className="text-end">
                                    <Link
                                      to="#"
                                      onClick={() => {
                                        seSelectedBid(bid.bid_id);
                                        handleShow();
                                      }}
                                    >
                                      Send Offer
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        animation={true}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-content-box vehicle-requests-main-modal "
      >
        <Modal.Header closeButton></Modal.Header>
        <div>
          <p className="text-center popup-heading mt-4">
            Are you sure you want to send this offer to shipper?{" "}
          </p>
        </div>
        <div className="btn-popup-modal text-center">
          <button className="popup-reject-btn me-4 ">Reject </button>
          <button className="popup-approve-btn m-1 ">
            <Link
              to="#"
              className="text-decoration-none"
              style={{ color: "#4FBFA4" }}
              onClick={() => sendOffer()}
            >
              Send
            </Link>
          </button>
        </div>
      </Modal>
      <Modal
        show={vehicleModel}
        onHide={handleClose}
        animation={true}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-content-box vehicle-requests-main-modal "
      >
        <Modal.Header closeButton onClick={() => setvehicleModel(false)}>
          Vehicle Details
        </Modal.Header>
        <Modal.Body>
          {auctionDetails?.vehicle_Details &&
            auctionDetails?.vehicle_Details.map((vehicle) => {
              return (
                <>
                  <div className="p-1  d-flex align-items-middle justify-content-between  rsponsive-card-data">
                    <h5 className="godside">Owner Name</h5>
                    <span className="allside">{vehicle.owner_name}</span>
                  </div>
                  <div className="p-1  d-flex align-items-middle justify-content-between  rsponsive-card-data">
                    <h5 className="godside">Driver Name</h5>
                    <span className="allside">{vehicle.driver_name}</span>
                  </div>
                  <div className="p-1  d-flex align-items-middle justify-content-between  rsponsive-card-data">
                    <h5 className="godside">Plate Number</h5>
                    <span className="allside">{vehicle.plate_number}</span>
                  </div>
                  <div className="p-1  d-flex align-items-middle justify-content-between  rsponsive-card-data">
                    <h5 className="godside">Vehicle Capacity</h5>
                    <span className="allside">{vehicle.vehicle_capacity}</span>
                  </div>
                  <div className="p-1  d-flex align-items-middle justify-content-between  rsponsive-card-data">
                    <h5 className="godside">Vehicle Type</h5>
                    <span className="allside">{vehicle.vehicle_type}</span>
                  </div>
                  <div className="p-1  d-flex align-items-middle justify-content-between  rsponsive-card-data">
                    <h5 className="godside">Vehicle Model No</h5>
                    <span className="allside">{vehicle.vehicle_model_no}</span>
                  </div>
                  <div className="p-1  d-flex align-items-middle justify-content-between  rsponsive-card-data">
                    <h5 className="godside">Nationality</h5>
                    <span className="allside">{vehicle.nationality}</span>
                  </div>
                  <hr />
                </>
              );
            })}
        </Modal.Body>
      </Modal>
    </main>
  );
};

export default Auctions;
