import React, { useState, useContext } from "react";
import ic_mail from "../assets/images/ic_mail.svg";
import { AppContext } from "../context/user/AppContext";
import { Link } from "react-router-dom";
const ContactUs = () => {

  const { mainClass } = useContext(AppContext);
  const [state, setState] = useState({ email: "", subject: "", text: "" });
  const [errMsg, setErrMsg] = useState({ email: "", subject: "", text: "" });
  const submit = () => {
    if (state.text === "") {
      if (state.email === "") {
        setErrMsg({
          ...errMsg, text: "Enter the message you wants to send"
        })
      }
    }
    else {

    }
  }
  return (
    <>
      <main className={`main-body-contactUs  ${mainClass}`} id="cxMain" >
        <div className="contactUs" >
          <div className="contact-us ">
            {/* contact left section */}
            <section className="left-section-contact  w-100 p-2">
              <h3 className="left-heading">Abay Logistics</h3>
              <p className="left-paragraph">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam
                voluptas pariatur ipsa error numquam commodi
              </p>
              <div className="email mt-2">
                <img src={ic_mail} alt="" /> <span className="left-email "> abaylogistics@gmail.com</span>
              </div>
            </section>
            {/* contact right section */}
            <section className="right-section-contact p-2 w-100">
              <p className="get-in-touch">Get In Touch With Us</p>
              <div className="form-group mt-4">
                <label htmlFor="exampleInputEmail1">Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  onChange={(e) => { setErrMsg({ ...errMsg, email: "" }); setState({ ...state, email: e.target.value }) }}
                />
                {errMsg.email.length > 0 &&
                  <div className="text-center text-danger">
                    <span> {errMsg.email}</span>
                  </div>
                }
              </div>
              <div className="form-group mt-4">
                <label htmlFor="exampleInputEmail1">Subject</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  onChange={(e) => { setErrMsg({ ...errMsg, subject: "" }); setState({ ...state, subject: e.target.value }) }}
                />
                {errMsg.subject.length > 0 &&
                  <div className="text-center text-danger">
                    <span> {errMsg.subject}</span>
                  </div>
                }
              </div>
              <div className="form-group mt-4">
                <label htmlFor="exampleFormControlTextarea1">text</label>
                <textarea
                  className="form-control text-area"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  onChange={(e) => { setErrMsg({ ...errMsg, text: "" }); setState({ ...state, text: e.target.value }) }}
                ></textarea>
                {errMsg.text.length > 0 &&
                  <div className="text-center text-danger">
                    <span> {errMsg.text}</span>
                  </div>
                }
              </div>
              <div className="mt-4">
                <Link to="" className="continue-btn" onClick={submit}>Continue</Link>
              </div>
            </section>
          </div>
        </div>
      </main>





    </>
  );
};

export default ContactUs;
