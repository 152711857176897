import React, { useContext } from "react";
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { AppContext } from "../../context/user/AppContext";

const Contracts = () => {
  const { mainClass } = useContext(AppContext);
  $(document).ready(function () {
    $('.dataList').DataTable(

    );
  });
  return (
    <div>
      {/* <Sidebar/>
         <AbhayHeader/> */}
      <main className={`contract-main-section ${mainClass}`} id="cxMainB">
        {/* Transporter Contract section */}
        <section className="transporter-contract-section">
          <div className="contract-top-bar">
            <p className="contract-top-heading">
              Transporter’s General Standard Contract (specifically for the
              Transporter)
            </p>
          </div>
          <div>
            <p className="contract-paragraph ">
              This Standard general agreement will be customized based on the
              type of agreement between transporters. It will be for one time or
              contract period; for transport Market place or Delivery of cargo;
              Customers who need the whole service of Abay or vehicle only.
            </p>
          </div>
          <div className="contract-rates" style={{ width: "100%", overflow: "auto" }} >
            <h4 className="card-heading">TRANSPORTATION RATE(S)</h4>
            <table className=" dataList" id="dataList">
              <thead>
                <tr>
                  <td>
                    <label htmlFor="">S.No.</label>
                  </td>
                  <td>
                    <label htmlFor="">Origin</label>
                  </td>
                  <td>
                    <label htmlFor="">Final Destination Point</label>
                  </td>
                  <td>
                    <label htmlFor="">
                      Weight (Metric, Ton, Quintals, etc.)
                    </label>
                  </td>
                  <td>
                    <label htmlFor="">Transit time (Day)</label>
                  </td>
                  <td>
                    <label htmlFor="">Rate Per 20ft Container</label>
                  </td>
                  <td>
                    <label htmlFor="">Rate Per 40ft Container</label>
                  </td>
                  <td>
                    <label htmlFor="">Rate/Quintal</label>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr className="t-row">
                  <td>
                    <p className="data m-0">1</p>
                  </td>
                  <td>
                    <p className="data m-0">Pune</p>
                  </td>
                  <td>
                    <p className="data m-0">Mumbai</p>
                  </td>
                  <td>
                    <p className="data m-0">20</p>
                  </td>
                  <td>
                    <p className="data m-0">2</p>
                  </td>
                  <td>
                    <p className="data m-0">356</p>
                  </td>
                  <td>
                    <p className="data m-0">450</p>
                  </td>
                  <td>
                    <p className="data m-0">450</p>
                  </td>
                </tr>
                <tr className="t-row">
                  <td>
                    <p className="data m-0">2</p>
                  </td>
                  <td>
                    <p className="data m-0">Pune</p>
                  </td>
                  <td>
                    <p className="data m-0">Mumbai</p>
                  </td>
                  <td>
                    <p className="data m-0">20</p>
                  </td>
                  <td>
                    <p className="data m-0">2</p>
                  </td>
                  <td>
                    <p className="data m-0">356</p>
                  </td>
                  <td>
                    <p className="data m-0">450</p>
                  </td>
                  <td>
                    <p className="data m-0">450</p>
                  </td>
                </tr>
                <tr className="t-row">
                  <td>
                    <p className="data m-0">3</p>
                  </td>
                  <td>
                    <p className="data m-0">Pune</p>
                  </td>
                  <td>
                    <p className="data m-0">Mumbai</p>
                  </td>
                  <td>
                    <p className="data m-0">20</p>
                  </td>
                  <td>
                    <p className="data m-0">2</p>
                  </td>
                  <td>
                    <p className="data m-0">356</p>
                  </td>
                  <td>
                    <p className="data m-0">450</p>
                  </td>
                  <td>
                    <p className="data m-0">450</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="button  text-right mt-2" style={{ padding: "0 20px" }}>
            <a href="" className="submit-btn ">
              View Full Contract            </a>
          </div>
        </section>
        {/* Shipper Contract Section */}
        <section className="shipper-contract-section mt-4 mb-4">
          <div className="contract-top-bar">
            <p className="contract-top-heading">
              Shippers Standard Contract Transport Market Place and Delivery
              System
            </p>
          </div>
          <div>
            <p className="contract-paragraph ">
              Shipper’s standard General contract that will be customized based
              on the type of required services whether it is Transport Market
              place or Delivery system.
            </p>
          </div>
          <div className="contract-rates" style={{ width: "100%", overflow: "auto" }}>
            <h4 className="card-heading">TRANSPORTATION RATE(S)</h4>
            <table className="table-striped dataList" id="example2">
              <thead>
                <tr>
                  <td>
                    <label htmlFor="">S.No.</label>
                  </td>
                  <td>
                    <label htmlFor="">Type of Charges</label>
                  </td>
                  <td>
                    <label htmlFor="">Per 20* if container are loaded paired</label>
                  </td>
                  <td>
                    <label htmlFor="">
                      Per 40gp
                    </label>
                  </td>
                  <td>
                    <label htmlFor="">Per Quintal</label>
                  </td>
                  <td>
                    <label htmlFor="">Currency</label>
                  </td>
                  <td>
                    <label htmlFor="">Remarks</label>
                  </td>

                </tr>
              </thead>
              <tbody>
                <tr className="Rowbgcolor">
                  <td>
                    <p className="data m-0">1</p>
                  </td>
                  <td>
                    <p className="data m-0">Port Handling Charges</p>
                  </td>
                  <td>
                    <p className="data m-0"></p>
                  </td>
                  <td>
                    <p className="data m-0"></p>
                  </td>
                  <td>
                    <p className="data m-0"></p>
                  </td>
                  <td>
                    <p className="data m-0"></p>
                  </td>
                  <td>
                    <p className="data m-0"></p>
                  </td>

                </tr>
                <tr className="t-row">
                  <td>
                    <p className="data m-0">2</p>
                  </td>
                  <td>
                    <p className="data m-0">Inland Transportation Charges</p>
                  </td>
                  <td>
                    <p className="data m-0"></p>
                  </td>
                  <td>
                    <p className="data m-0"></p>
                  </td>
                  <td>
                    <p className="data m-0"></p>
                  </td>
                  <td>
                    <p className="data m-0"></p>
                  </td>
                  <td>
                    <p className="data m-0"></p>
                  </td>

                </tr>
                <tr className="t-row">
                  <td>
                    <p className="data m-0">3</p>
                  </td>
                  <td>
                    <p className="data m-0">Service/Clearance Fee</p>
                  </td>
                  <td>
                    <p className="data m-0"></p>
                  </td>
                  <td>
                    <p className="data m-0"></p>
                  </td>
                  <td>
                    <p className="data m-0"></p>
                  </td>
                  <td>
                    <p className="data m-0"></p>
                  </td>
                  <td>
                    <p className="data m-0"></p>
                  </td>

                </tr>
              </tbody>
            </table>
          </div>
          <div className="button  text-right mt-2" style={{ padding: "0 20px" }}>
            <a href="" className="submit-btn ">
              View Full Contract            </a>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Contracts;
